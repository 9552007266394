/* eslint-disable import/no-anonymous-default-export */
import {
    GET_USERS,
    CREATE_USER,
    PATCH_USER,
    DELETE_USER,
    SET_USER,
    SET_COMPANY_ID,
    SET_COMPANY_NAME,
    RESET_AUTH,
    SET_IS_AUTH,
    SET_AUTH_TOKEN,
    SET_USER_VIEW,
    SET_HIDDEN_COMPANY_ID,
    SET_COMPANY_USER_FILTER
} from '../constants/actionTypes';

const initialState = {
    users: [],
    userId: '',
    companyId: '',
    companyName: '',
    isAuth: false,
    authToken: '',
    hiddenCompanyId: '',
    userView: true,
    companyUserFilter: 'company'
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS.SUCCESS:
            return {
              ...state,
              users: [...action.array]
            };
        case CREATE_USER.SUCCESS:
            return {
              ...state,
              users: [...state.users, action.obj]
            };
        case PATCH_USER.SUCCESS:
            return {
              ...state,
              users: state.users.map((obj) => {
                if (obj.id === action.obj.id) {
                  return action.obj;
                }
                return obj;
              })
            };
        case DELETE_USER.SUCCESS:
            return {
              ...state,
              users: state.users.filter((obj) => {
                return obj.id !== action.id;
              })
            };
          case SET_USER:
            return {
              ...state,
              userId: action.payload
            };
          case SET_COMPANY_ID:
            return {
              ...state,
              companyId: action.payload
            };
          case SET_HIDDEN_COMPANY_ID:
            return {
              ...state,
              hiddenCompanyId: action.payload
            };
          case SET_USER_VIEW:
            return {
              ...state,
              userView: action.payload
            };
          case SET_COMPANY_NAME:
            return {
              ...state,
              companyName: action.payload
            };
          case SET_COMPANY_USER_FILTER:
            return {
              ...state,
              companyUserFilter: action.payload
            };
          case SET_IS_AUTH:
            return {
              ...state,
              isAuth: action.payload
            };
          case SET_AUTH_TOKEN:
            return {
              ...state,
              authToken: action.payload
            };
          case RESET_AUTH:
            return {
              ...state,
              users: [],
              userId: '',
              companyId: '',
              hiddenCompanyId: '',
              userView: true,
              companyName: '',
              authToken: '',
              companyUserFilter: 'company'
            };
        case "SIGNOUT": {
                return initialState;
            }
        default:
            return state;
    }
};