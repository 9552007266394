import { 
    SET_TITLE,
    SET_INSTRUCTIONS,
    SET_BACKGROUND,
    SET_LOGO,
    SET_ACTIVE,
    SET_INACTIVE,
    SET_HIT,
    SET_MISS,
    SET_GAME_OVER,
    SET_PRESENTATIONS,
    SET_PRESENTATION_ID,
    SET_CURRENT_PRESENTATION,
    SET_FILES,
    RESET_CONTENT,
    CLEAR_PRESENTATION,
    CLEAR_ALL_PRESENTATIONS,
    UPDATE_PRESENTATION_TITLE_INNER,
    UPDATE_PRESENTATION_TITLE_OUTER,
    SET_AVAILABLE_BACKGROUNDS,
    CLEAR_BACKGROUNDS,
    SET_SCORE_INCREMENT,
    SET_TIMER_LENGTH,
    SET_IS_NEW,
    SET_LAST_UPDATED,
    SET_HAS_BEEN_UPLOADED,
    UPDATE_IS_NEW_INNER,
    UPDATE_IS_NEW_OUTER,
    UPDATE_HAS_BEEN_UPLOADED_INNER,
    UPDATE_HAS_BEEN_UPLOADED_OUTER,
    UPDATE_LAST_UPDATED_INNER,
    UPDATE_LAST_UPDATED_OUTER,
    SET_SCORE_INCREMENT_INNER,
    SET_SCORE_INCREMENT_OUTER,
    SET_TIMER_LENGTH_INNER,
    SET_TIMER_LENGTH_OUTER,
    SET_GAME_TITLE,
    SET_GAME_TITLE_INNER,
    SET_GAME_TITLE_OUTER,
    SET_USED_NAMES,
    SET_BACKGROUND_COLOR,
    SET_BACKGROUND_COLOR_INNER,
    SET_BACKGROUND_COLOR_OUTER,
    SET_TEXT_COLOR,
    SET_TEXT_COLOR_INNER,
    SET_TEXT_COLOR_OUTER,
    SET_ALERT_COLOR,
    SET_ALERT_COLOR_INNER,
    SET_ALERT_COLOR_OUTER
} from '../constants/actionTypes';

export function setAlertColor(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ALERT_COLOR,
            payload: string
        });  
    }  
};

export function setAlertColorInner(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ALERT_COLOR_INNER,
            payload: string
        });  
    }  
};

export function setAlertColorOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ALERT_COLOR_OUTER,
            payload: obj
        });  
    }  
};

export function setTextColor(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TEXT_COLOR,
            payload: string
        });  
    }  
};

export function setTextColorInner(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TEXT_COLOR_INNER,
            payload: string
        });  
    }  
};

export function setTextColorOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TEXT_COLOR_OUTER,
            payload: obj
        });  
    }  
};

export function setBackgroundColor(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_COLOR,
            payload: string
        });  
    }  
};

export function setBackgroundColorInner(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_COLOR_INNER,
            payload: string
        });  
    }  
};

export function setBackgroundColorOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND_COLOR_OUTER,
            payload: obj
        });  
    }  
};

export function setGameTitle(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GAME_TITLE,
            payload: string
        });  
    }  
};

export function setGameTitleInner(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GAME_TITLE_INNER,
            payload: string
        });  
    }  
};

export function setGameTitleOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GAME_TITLE_OUTER,
            payload: obj
        });  
    }  
};

export function setTimerLengthInner(int) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TIMER_LENGTH_INNER,
            payload: int
        });  
    }  
};

export function setTimerLengthOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TIMER_LENGTH_OUTER,
            payload: obj
        });  
    }  
};

export function setScoreIncrementInner(int) {  
    return dispatch => {  
        return dispatch({  
            type: SET_SCORE_INCREMENT_INNER,
            payload: int
        });  
    }  
};

export function setScoreIncrementOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_SCORE_INCREMENT_OUTER,
            payload: obj
        });  
    }  
};

export function setLastUpdatedInner(date) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_LAST_UPDATED_INNER,
            payload: date
        });  
    }  
};

export function setLastUpdatedOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_LAST_UPDATED_OUTER,
            payload: obj
        });  
    }  
};



export function setIsNewInner(bool) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_IS_NEW_INNER,
            payload: bool
        });  
    }  
}; 

export function setIsNewOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_IS_NEW_OUTER,
            payload: obj
        });  
    }  
}; 

export function setHasBeenUploadedInner(bool) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_HAS_BEEN_UPLOADED_INNER,
            payload: bool
        });  
    }  
}; 

export function setHasBeenUploadedOuter(obj) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_HAS_BEEN_UPLOADED_OUTER,
            payload: obj
        });  
    }  
};

export function setTitle(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TITLE,
            payload: string
        });  
    }  
}; 

export function setInstructions(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_INSTRUCTIONS,
            payload: path
        });  
    }  
}; 

export function setBackground(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_BACKGROUND,
            payload: path
        });  
    }  
}; 

export function setLogo(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_LOGO,
            payload: path
        });  
    }  
}; 

export function setActive(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_ACTIVE,
            payload: path
        });  
    }  
};

export function setInactive(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_INACTIVE,
            payload: path
        });  
    }  
};

export function setHit(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_HIT,
            payload: path
        });  
    }  
};

export function setMiss(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_MISS,
            payload: path
        });  
    }  
};

export function setGameOver(path) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GAME_OVER,
            payload: path
        });  
    }  
};

export function setPresentations(presentations) {  
    return dispatch => {  
        return dispatch({  
            type: SET_PRESENTATIONS,
            payload: presentations
        });  
    }  
};

export function setCurrentPresentation(obj) {  
    return dispatch => {  
        return dispatch({  
            type: SET_CURRENT_PRESENTATION,
            payload: obj
        });  
    }  
};

export function setPresentationId(id) {  
    return dispatch => {  
        return dispatch({  
            type: SET_PRESENTATION_ID,
            payload: id
        });  
    }  
};

export function setFiles(files) {  
    return dispatch => {  
        return dispatch({  
            type: SET_FILES,
            payload: files
        });  
    }  
};

export function updatePresentationTitleInner(title) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_PRESENTATION_TITLE_INNER,
            payload: title
        });  
    }  
};

export function updatePresentationTitleOuter(presentation) {  
    return dispatch => {  
        return dispatch({  
            type: UPDATE_PRESENTATION_TITLE_OUTER,
            payload: presentation
        });  
    }  
};

export function setAvailableBackgrounds(backgroundObjects) {  
    return dispatch => {  
        return dispatch({  
            type: SET_AVAILABLE_BACKGROUNDS,
            payload: backgroundObjects
        });  
    }  
};

export function setUsedNames(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_USED_NAMES,
            payload: string
        });  
    }  
};

export function clearBackgrounds() {  
    return dispatch => {  
        return dispatch({  
            type: CLEAR_BACKGROUNDS
        });  
    }  
};

export function resetContent() {  
    return dispatch => {  
        return dispatch({  
            type: RESET_CONTENT
        });  
    }  
};

export function clearPresentation() {  
    return dispatch => {  
        return dispatch({  
            type: CLEAR_PRESENTATION
        });  
    }  
};

export function clearAllPresentations() {  
    return dispatch => {  
        return dispatch({  
            type: CLEAR_ALL_PRESENTATIONS
        });  
    }  
};

export function setScoreIncrement(int) {  
    return dispatch => {  
        return dispatch({  
            type: SET_SCORE_INCREMENT,
            payload: int
        });  
    }  
};

export function setTimerLength(int) {  
    return dispatch => {  
        return dispatch({  
            type: SET_TIMER_LENGTH,
            payload: int
        });  
    }  
};

export function setIsNew(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_IS_NEW,
            payload: bool
        });  
    }  
};

export function setLastUpdated(date) {  
    return dispatch => {  
        return dispatch({  
            type: SET_LAST_UPDATED,
            payload: date
        });  
    }  
};

export function setHasBeenUploaded(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_HAS_BEEN_UPLOADED,
            payload: bool
        });  
    }  
};