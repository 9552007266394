/* eslint-disable import/no-anonymous-default-export */
import {
    SET_HIT_PLAYING,
    SET_MISS_PLAYING,
    SET_GAME_OVER_PLAYING,
    RESET_VIEW
} from '../constants/actionTypes';

const initialState = {
    isHitPlaying: false,
    isMissPlaying: false,
    isGameOverPlaying: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_HIT_PLAYING:
            return {
                ...state,
                isHitPlaying: action.payload
            };
        case SET_MISS_PLAYING:
            return {
                ...state,
                isMissPlaying: action.payload
            };
        case SET_GAME_OVER_PLAYING:
            return {
                ...state,
                isGameOverPlaying: action.payload
            };
        case RESET_VIEW:
            return {
                ...state,
                isHitPlaying: false,
                isMissPlaying: false,
                isGameOverPlaying: false
            };
        default:
            return state;
    }
};