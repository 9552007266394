import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebase.config';
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence, onAuthStateChanged, inMemoryPersistence } from 'firebase/auth'
class Firebase {
  constructor() {
    const app = initializeApp(firebaseConfig);
    this.auth = getAuth();
    this.user = this.auth.currentUser;
    this.db = getFirestore(app)
    onAuthStateChanged(this.auth,(user)=>{
        this.user = user;
    });
    setPersistence(this.auth, inMemoryPersistence);
  }
  doSignInWithEmailAndPassword = (email,password) => {
    return signInWithEmailAndPassword(this.auth, email,password);
  }
}
 
export default Firebase;