import { connect } from 'react-redux';
import Login from './Login';
import {
  setUser,
  setCompanyName,
  setCompanyId,
  setPresentations,
  setIsAuth,
  setAuthToken,
  setHiddenCompanyId,
  setUserView
} from '../../actions';

const mapStateToProps = (state) => ({
  
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (id) => dispatch(setUser(id)),
    setCompanyName: (string) => dispatch(setCompanyName(string)),
    setCompanyId: (id) => dispatch(setCompanyId(id)),
    setPresentations: (presentations) => dispatch(setPresentations(presentations)),
    setIsAuth: (bool) => dispatch(setIsAuth(bool)),
    setAuthToken: (token) => dispatch(setAuthToken(token)),
    setHiddenCompanyId: (string) => dispatch(setHiddenCompanyId(string)),
    setUserView: (bool) => dispatch(setUserView(bool))
  };
};

const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);

export default LoginContainer;
