import React, { useState, useEffect, useRef } from 'react';
import { HexColorPicker, HexColorInput } from "react-colorful";
import styled from 'styled-components';
import {
    createFile,
    updatePresentation,
    deleteFile,
    buildFile,
    beginDownload,
    createInstructionsFile
    // getADV,
    // updateFile
} from '../../utils/API';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
// import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
// import defaultBackground from '../../assets/images/background.jpg';
import f2b from '../../assets/images/f2bbackground.jpg';
// import testMP3 from '../../assets/audio/mp3.mp3';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VolumeUpRoundedIcon from '@mui/icons-material/VolumeUpRounded';
import VolumeMuteRoundedIcon from '@mui/icons-material/VolumeMuteRounded';
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
// import { v4 as uuidv4 } from 'uuid';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import ArrowBackRounded from '@mui/icons-material/ArrowBackRounded';
import { Spinner } from 'react-bootstrap';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import ReactTooltip from 'react-tooltip';

const StyledContentContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    @media (max-width: 1024px) and (orientation: portrait) {
        justify-content: space-around;
    }

    .background-layer {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        opacity: 0.2;
    }

    .color-modal {
        position: absolute;
        z-index: 3;
        height: 280px;
        width: 600px;
        top: calc(50% - 140px);
        left: calc(50% - 300px);
        background-color: white;
        transition: 0.5s ease all;
        box-shadow: 3px 3px 8px 1px #00000040;
        border-radius: 4px;
        justify-content: center;
        align-items: center;

        .close-modal {
            position: absolute;
            top: 0;
            right: 0;
            height: min-content;
            width: min-content;
            cursor: pointer;

            svg {
                font-size: 30px;
                color: #e6300b;

                @media (max-width: 1200px) and (orientation: landscape) {
                    font-size: 28px;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 34px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    font-size: 28px;
                }
            }
        }

        .center-band {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .color-band {
                height: 100%;
                width: 33.33%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .color-box {
                    height: 80%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .react-colorful {
                        height: 130px;
                        width: 130px;
                        border-top-right-radius: 4px;
                        border-top-left-radius: 4px;

                        .react-colorful__saturation {
                            border-radius: 0px;
                            border-top-right-radius: 4px;
                            border-top-left-radius: 4px;
                        }

                        .react-colorful__hue {
                            border-radius: 0px;
                        }
                    }

                    input {
                        height: 30px;
                        width: 130px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }

                .text-box {
                    height: 20%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    p {
                        font-size: 15px;
                        font-family: 'Merriweater Sans', sans-serif;
                        margin-block: 0px;
                        font-weight: 600;
                        color: #3b3b3b;
                    }
                }
            }
        }
    }

    .color-modal-background {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        background-color: #3b3b3b80;
        transition: 0.5s ease all;
    }

    .instructions-modal {
        position: absolute;
        z-index: 3;
        height: 300px;
        width: 500px;
        top: calc(50% - 150px);
        left: calc(50% - 250px);
        background-color: white;
        transition: 0.5s ease all;
        box-shadow: 3px 3px 8px 1px #00000040;
        border-radius: 4px;
        justify-content: center;
        align-items: center;

        .close-modal {
            position: absolute;
            top: 0;
            right: 0;
            height: min-content;
            width: min-content;
            cursor: pointer;

            svg {
                font-size: 40px;
                color: #e6300b;

                @media (max-width: 1200px) and (orientation: landscape) {
                    font-size: 28px;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                    font-size: 34px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    font-size: 28px;
                }
            }
        }

        .manual-box {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 45%;
            height: 100%;

            .image {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .file-name {
                    width: 80%;
                    height: 25px;
                    margin: 1% 0px;
                    padding: 5px;
                    text-align: center;
                    font-size: 12px;
                }

                .file-body {
                    width: 80%;
                    height: 140px;
                    margin: 1% 0px;
                    text-align: left;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    vertical-align: middle;
                    font-size: 12px;
                    padding: 15%;
                }

                .upload-button {
                    width: 80%;
                    height: 25px;
                    background-color: rgb(7,104,176);
                    border-radius: 4px;
                    box-shadow: 2px 3px 5px 0px #3b3b3b80;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 1% 0px;
                    cursor: pointer;

                    svg {
                        font-size: 24px;
                        color: white;
                        margin: 0px 5px;
                        cursor: inherit;
                    }
    
                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 14px;
                        color: white;
                        cursor: inherit;
                        user-select: none;
                        margin-right: 10px;
                        cursor: inherit;
                    }
                }

                .instructions {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80%;
                    height: 35px;
                    margin: 1% 0px;

                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans',sans-serif;
                        font-size: 12px;
                        font-weight: 600;
                        color: #3b3b3b;
                        text-align: center;
                    }
                }
            }
        }

        .or {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 10%;
            padding-left: 20px;

            p {
                margin-block: 0px;
                font-family: 'Merriweather Sans', sans-serif;
                font-size: 14px;
                font-weight: 600;
                color: #3b3b3b;
                height: auto;
                width: auto;

                @media (max-width: 1200px) and (orientation: landscape) {
                    font-size: 11px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    font-size: 11px;
                }
            }
        }

        .upload-instructions {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            width: 45%;
            height: 100%;

            .image {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .image-inner {
                    height: 150px;
                    width: 150px;
                    border-radius: 50%;
                    background-color: #3b3b3b;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;

                    p {
                        color: white;
                        font-size: 10px;
                        user-select: none;
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        text-align: center;
                        width: 80%;

                        @media (max-width: 1366px) and (orientation: landscape) {
                            font-size: 8px;
                        }

                        @media (max-width: 1024px) and (orientation: portrait) {
                            font-size: 8px;
                        }
                    }

                    svg {
                        font-size: 54px;
                        color: white;
                    }
                }
                
                .text {
                    height: min-content;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 10px 0px;

                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: #3b3b3b;
                        text-align: center;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 12px;
                        }

                        @media (max-width: 1024px) and (orientation: portrait) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .instructions-modal-background {
        position: absolute;
        z-index: 2;
        height: 100%;
        width: 100%;
        background-color: #3b3b3b80;
        transition: 0.5s ease all;
    }

    p {
        user-select: none;
        cursor: default;
    }

    .presentation-select {
        position: absolute;
        top: 0;
        right: 0;
        height: 80px;
        width: 160px;
        z-index: 1;
        border-bottom-left-radius: 8px;
        box-shadow: -1px 1px 3px 0px #3b3b3b80;
        background-color: rgb(7, 104, 176);
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 1200px) and (orientation: landscape) {
            height: 60px;
            width: 120px;
        }

        svg {
            font-size: 28px;
            color: white;
            cursor: pointer;
        }
    }

    .preview-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 30%;

        @media (max-width: 1024px) and (orientation: portrait) {
            width: 40%;
        }

        .preview-box {
            height: 768px;
            width: 432px;   
            box-shadow: 5px 5px 8px 1px #3b3b3b80;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border: 2px solid #3b3b3b40;
            position: relative;

            @media (max-width: 1367px) and (orientation: landscape) {
                height: 614px;
                width: 345px;
                margin-bottom: 100px;
            }

            @media (max-width: 1200px) and (orientation: landscape) {
                height: 460px;
                width: 259px;
            }

            @media (max-width: 1024px) and (orientation: portrait) {
                margin-bottom: 150px;
            }

            @media (max-width: 900px) and (orientation: portrait) {
                height: 460px;
                width: 259px;
                margin-bottom: 0px;
            }

            .color-select-box {
                position: absolute;
                top: -55px;
                height: 50px;
                width: min-content;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                section {
                    border-radius: 10px;
                    box-shadow: 1px 1px 3px 0px #3b3b3b80;
                    height: 30px;
                    width: 30px;
                    background-image: linear-gradient(135deg, red, orange, yellow, green, blue, indigo, violet);
                    cursor: pointer;
                }

                p {
                    font-size: 14px;
                    font-family: 'Merriweather Sans', sans-serif;
                    color: #3b3b3b;
                    margin-block: 0px;
                    font-weight: 600;
                    width: 100px;
                    cursor: pointer;
                }
            }

            .top-banner {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 10%;

                section {
                    height: 100%;
                    width: 33.33%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .inner-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        height: 85%;
                        width: 60%;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            width: 75%;
                        }

                        @media (max-width: 900px) and (orientation: portrait) {
                            width: 75%;
                        }

                        div {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            p {
                                font-family: 'Scoreboard', cursive;
                                font-weight: 400;
                                margin-block: 0px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                letter-spacing: 0.5px;
                            }
                        }

                        .top, .bottom {
                            width: 100%;
                            height: 25%;

                            p {
                                font-size: 14px;

                                @media (max-width: 1200px) and (orientation: landscape) {
                                    font-size: 9px;
                                }

                                @media (max-width: 900px) and (orientation: portrait) {
                                    font-size: 9px;
                                }
                            }
                        }

                        .center {
                            width: 100%;
                            height: 50%;

                            p {
                                font-size: 28px;

                                @media (max-width: 1200px) and (orientation: landscape) {
                                    font-size: 19px;
                                }

                                @media (max-width: 900px) and (orientation: portrait) {
                                    font-size: 19px;
                                }
                            }

                            &.timer-center {
                                p {

                                }
                            }
                        }
                    }
                }
            }

            .image-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 80%;
                background-color: white;
                position: relative;

                .icon-container {
                    position: absolute;
                    height: min-content;
                    width: 100%;
                    bottom: 5%;
                    left: 0;
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    
                    .single-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: min-content;
                        width: min-content;
                        flex-direction: column;

                        .image {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            object-fit: contain;
                            height: 80px;
                            width: 80px;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                height: 60px;
                                width: 60px;
                            }

                            @media (max-width: 900px) and (orientation: portrait) {
                                height: 60px;
                                width: 60px;
                            }
    
                            img {
                                height: auto;
                                width: 100%;
                                max-height: 100%;
                                max-width: 100%;
                            }
                        }

                        .title {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 25px;
                            width: 80px;
                            background-color: #3b3b3b;
                            border-radius: 4px;
                            margin-top: 5px;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                width: 65px;
                            }

                            @media (max-width: 900px) and (orientation: portrait) {
                                width: 65px;
                            }

                            p {
                                color: white;
                                margin-block: 0px;
                                font-size: 12px;
                                font-weight: 600;
                                font-family: 'Space Mono', serif;

                                @media (max-width: 1200px) and (orientation: landscape) {
                                    font-size: 11px;
                                }

                                @media (max-width: 900px) and (orientation: portrait) {
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }

            .bottom-banner {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 10%;
                padding: 20px;
                overflow: hidden;

                p {
                    font-family: "Scoreboard", cursive;
                    font-size: 28px;
                    font-weight: 400;
                    margin-block: 0px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    letter-spacing: 0.5px;

                    @media (max-width: 1200px) and (orientation: landscape) {
                        font-size: 22px;
                    }

                    @media (max-width: 900px) and (orientation: portrait) {
                        font-size: 22px;
                    }
                }
            }
        }
    }

    .upload-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 80%;
        width: 70%;
        position: relative;

        @media (max-width: 1367px) and (orientation: landscape) {
            margin-bottom: 100px;
        }

        @media (max-width: 1200px) and (orientation: landscape) {
            height: 75%;
        }

        @media (max-width: 1024px) and (orientation: portrait) {
            width: 50%;
            margin-bottom: 150px;
        }

        @media (max-width: 900px) and (orientation: portrait) {
            margin-bottom: 0px;
        }

        .inner {
            height: 30%;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            @media (max-width: 1024px) and (orientation: portrait) {
                height: 20%;
            }

            .upload-box {
                height: 200px;
                width: 165px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 140px;
                    width: 115.5px;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                    height: 140px;
                    width: 115.5px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    height: 120px;
                    width: 105px;
                }

                .image {
                    height: 80%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    flex-direction: column;

                    .image-inner {
                        height: 85%;
                        width: 85%;
                        background-color: #3b3b3b;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        cursor: pointer;
                        box-shadow: 2px 2px 4px 0px #3b3b3b80;

                        p {
                            color: white;
                            font-size: 10px;
                            user-select: none;
                            margin-block: 0px;
                            font-family: 'Merriweather Sans', sans-serif;
                            text-align: center;
                            width: 80%;

                            @media (max-width: 1366px) and (orientation: landscape) {
                                font-size: 8px;
                            }

                            @media (max-width: 1024px) and (orientation: portrait) {
                                font-size: 8px;
                            }
                        }

                        svg {
                            font-size: 54px;
                            color: white;
                        }
                    }

                    .uploaded-image {
                        height: 70%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: relative;
                        object-fit: contain;

                        img {
                            height: 100%;
                            max-height: 100%;
                            max-width: 100%;
                            width: auto;
                            object-fit: contain;
                        }

                        svg {
                            font-size: 96px;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                font-size: 60px;
                            }

                            @media (max-width: 1024px) and (orientation: portrait) {
                                font-size: 96px;
                            }

                            @media (max-width: 900px) and (orientation: portrait) {
                                font-size: 60px;
                            }
                        }
                    }

                    .uploaded-title {
                        height: 30%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;
                        padding-left: 5px;
                        padding-right: 5px;

                        p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-block: 0px;
                            font-family: 'Merriweather Sans', sans-serif;
                            font-size: 12px;
                            color: #3b3b3b;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                font-size: 11px;
                            }
                        }
                    }

                    .delete {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: min-content;
                        width: min-content;
                        cursor: pointer;

                        svg {
                            font-size: 40px;
                            color: #e6300b;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                font-size: 28px;
                            }

                            @media (max-width: 1024px) and (orientation: portrait) {
                                font-size: 34px;
                            }

                            @media (max-width: 900px) and (orientation: portrait) {
                                font-size: 28px;
                            }
                        }
                    }

                    audio {
                        height: 85%;
                        width: 85%;
                        background-color: #3b3b3b;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }
                }

                .text {
                    height: 20%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: #3b3b3b;
                        text-align: center;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 12px;
                        }

                        @media (max-width: 1024px) and (orientation: portrait) {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .image-container {
            align-items: flex-end;

            .upload-box {
                .image {
                    .image-inner {
                        background-color: #888888;
                        border-radius: 50%;
                        border: 2px solid #3b3b3b1A;
                        box-shadow: 2px 2px 4px 0px #3b3b3b80;
                    }
                }
            }
        }

        .sound-container {
            align-items: flex-end;

            .upload-box{
                .image {
                    .image-inner {
                        border-radius: 50%;
                    }
                }
            }
        }

        .text-container {
            align-items: flex-end;

            @media (max-width: 900px) and (orientation: portrait) {
                height: 15%;
            }

            .upload-box {
                margin-right: 4%;

                .image {
                    .image-inner {
                        background-color: #3b3b3b;
                        border-radius: 50%;
                        border: 2px solid #3b3b3b1A;
                        box-shadow: 2px 2px 4px 0px #3b3b3b80;
                    }
                }
            }

            .input-box {
                height: 80%;
                width: 400px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 140px;
                    width: 220px;
                }

                @media (max-width: 1024px) and (orientation: portrait) {
                    height: 200px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    height: 100%;
                }

                .top-and-bottom {
                    height: 50%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    overflow: hidden;

                    &.top {
                        height: 60%;
                    }

                    &.bottom {
                        height: 40%;
                        // justify-content: flex-start;
                    }
        
                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: #3b3b3b;
                        height: auto;
                        width: auto;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 11px;
                        }

                        @media (max-width: 900px) and (orientation: portrait) {
                            font-size: 11px;
                        }
                    }

                    input {
                        height: 50%;
                        width: 100%;
                        margin-block: 0px;
                        font-family: 'Scoreboard', monospace;
                        font-size: 44px;
                        font-weight: 700;
                        color: #3b3b3b; 
                        text-align: center;
                        background-color: transparent;
                        border: none;
                        text-overflow: ellipsis;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 24px;
                        }

                        @media (max-width: 1024px) and (orientation: portrait) {
                            font-size: 22px;
                        }

                        @media (max-width: 900px) and (orientation: portrait) {
                            font-size: 18px;
                        }

                        &:focus {
                            outline: none;
                        }
                    }

                    .presentation-name {
                        height: 50%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        p {
                            font-weight: 700;
                            color: #3b3b3b;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: center;
                            font-size: 22px;
                        }
                    }
                }
            }

            .integer-box {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 200px;
                width: 250px;

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 140px;
                    width: 140px;
                }

                @media (max-width: 900px) and (orientation: portrait) {
                    height: 100%;
                }

                .integer {
                    width: 100%;
                    height: 55%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p, span {
                        margin-block: 0px;
                        font-family: 'Scoreboard', monospace;
                        font-size: 48px;
                        font-weight: 700;
                        color: #3b3b3b;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: center;
                    }

                    span {
                        font-size: 24px;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 18px;
                        }
                    }

                    input {
                        height: 100%;
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        font-family: 'Scoreboard', monospace;
                        font-size: 48px;
                        font-weight: 700;
                        color: #3b3b3b;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 30px;
                        }

                        @media (max-width: 1024px) and (orientation: portrait) {
                            font-size: 26px;
                        }
                        
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .tip-box {
                    width: 100%;
                    height: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 12px;
                        color: #3b3b3b;
                        height: auto;
                        width: auto;
                    }
                }

                .controller {
                    width: 100%;
                    height: 20%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .incrementer {
                        height: 80%;
                        width: 15%;
                        background-color: rgb(7,104,176);
                        cursor: pointer;
                        transition: 0.1s ease all;
                        margin: 1px;
                        box-shadow: 2px 3px 5px 0px #3b3b3b80; 
                        border: 2px solid #3b3b3b1A;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:hover {
                            filter: brightness(120%);
                        }

                        p {
                            color: white;
                            margin-block: 0px;
                            font-size: 16px;
                            cursor: inherit;
                            font-family: 'Merriweather Sans',sans-serif;
                        }

                        svg {
                            color: white;
                            font-size: 24px;

                            @media (max-width: 1200px) and (orientation: landscape) {
                                font-size: 18px;
                            }
                        }
                    }
                }

                .title {
                    width: 100%;
                    height: 15%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        margin-block: 0px;
                        font-family: 'Merriweather Sans', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                        color: #3b3b3b;
                        height: auto;
                        width: auto;

                        @media (max-width: 1200px) and (orientation: landscape) {
                            font-size: 11px;
                        }

                        @media (max-width: 900px) and (orientation: portrait) {
                            font-size: 11px;
                        }
                    }
                }
            }
        }

        .save-container {
            height: 10%;
            align-items: flex-end;
            justify-content: center;
            position: relative;

            @media (max-width: 1367px) and (orientation: landscape) {
                align-items: center;
            }

            .iu2-notification {
                height: 45px;
                width: 230px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 2%;
                transition: 0.3s ease all;

                p {
                    margin-block: 0px;
                    font-family: 'Merriweather Sans', sans-serif;
                    font-size: 10px;
                    font-weight: 600;
                    color: #3b3b3b80;
                    user-select: none;
                    text-align: center;
                }
            }

            .downloading-spinner {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 5px;
                height: 40px;

                .spinner-border {
                    height: 1.4rem !important;
                    width: 1.4rem !important;
                }
            }

            .save-button {
                height: 45px;
                width: 230px;
                background-color: ${props => props.isNew && props.isFinished ? 'rgb(7,104,176)' : '#dcdde0'};
                border-radius: 4px;
                box-shadow: 2px 3px 5px 0px #3b3b3b80; 
                border: 2px solid #3b3b3b1A;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: ${props => props.isNew && props.isFinished ? 'pointer' : 'default'};
                transition: 0.1s ease all;
                filter: ${props => props.buttonHovered && props.isNew && props.isFinished ? 'brightness(120%)' : 'brightness(100%)'};

                svg {
                    font-size: 24px;
                    color: ${props => props.isNew && props.isFinished ? 'white' : '#3b3b3b80'};
                    margin: 0px 5px;
                }

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 35px;
                    width: 180px;
                }

                p {
                    margin-block: 0px;
                    font-family: 'Merriweather Sans', sans-serif;
                    font-size: 19px;
                    color: ${props => props.isNew && props.isFinished ? 'white' : '#3b3b3b80'};
                    cursor: inherit;
                    user-select: none;
                    margin-right: 10px;

                    @media (max-width: 1200px) and (orientation: landscape) {
                        font-size: 16px;
                    }
                }
            }

            .updated-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 2%;
                height: 45px;
                width: 230px;

                @media (max-width: 1200px) and (orientation: landscape) {
                    height: 35px;
                    width: 180px;
                }

                p {
                    margin-block: 0px;
                    font-family: 'Merriweather Sans', sans-serif;
                    font-size: 10px;
                    font-weight: 600;
                    color: #3b3b3b80;
                    user-select: none;
                    text-align: center
                }
            }
        }
    }
`;

const StyledInput = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`;

const Content = ({
    isAuth,
    setIsAuth,
    title,
    setTitle,
    instructions,
    setInstructions,
    background,
    setBackground,
    logo,
    setLogo,
    active,
    setActive,
    inactive,
    setInactive,
    hit,
    setHit,
    miss,
    setMiss,
    gameOver,
    setGameOver,
    isHitPlaying,
    setHitPlaying,
    isMissPlaying,
    setMissPlaying,
    isGameOverPlaying,
    setGameOverPlaying,
    presentationId,
    clearPresentation,
    resetContent,
    resetAuth,
    resetView,
    updatePresentationTitleInner,
    updatePresentationTitleOuter,
    timerLength,
    scoreIncrement,
    setTimerLength,
    setScoreIncrement,
    companyName,
    companyId,
    setLastUpdated,
    setIsNew,
    isNew,
    lastUpdated,
    hasBeenUploaded,
    setHasBeenUploaded,
    setTimerLengthInner,
    setTimerLengthOuter,
    setScoreIncrementInner,
    setScoreIncrementOuter,
    setLastUpdatedInner,
    setLastUpdatedOuter,
    setHasBeenUploadedInner,
    setHasBeenUploadedOuter,
    setIsNewInner,
    setIsNewOuter,
    setGameTitle,
    setGameTitleInner,
    setGameTitleOuter,
    gameTitle,
    backgroundColor,
    textColor,
    alertColor,
    setBackgroundColor,
    setBackgroundColorInner,
    setBackgroundColorOuter,
    setTextColor,
    setTextColorInner,
    setTextColorOuter,
    setAlertColor,
    setAlertColorInner,
    setAlertColorOuter
}) => {
    const [buttonHovered, setButtonHovered] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [showCheck, setShowCheck] = useState(false);
    const [showIU2Message, setShowIU2Message] = useState(false);
    const [showInstructionsModal, setShowInstructionsModal] = useState(false);
    const [showColorModal, setShowColorModal] = useState(false);
    const [instructionsBody, setInstructionsBody] = useState("");
    const history = useHistory();
    const hitRef = useRef();
    const missRef = useRef();
    const gameOverRef = useRef();

    useEffect(() => {
        if (!isAuth) {
            history.push('/');
        }
    }, [isAuth, history]);

    const handleShowInstructionsModal = () => {
        setShowInstructionsModal(!showInstructionsModal);
    }

    const handleShowColorModal = () => {
        setShowColorModal(!showColorModal);
    }

    const handleSetInstructionsBody = (event) => {
        if (event.target.value.length < 50) {
            setInstructionsBody(event.target.value);
        } else {
            return;
        }
    }

    const handleInstructionsUpload = () => {
        let instructionsObj = {
            body: instructionsBody
        }
        handleShowInstructionsModal();
        createInstructionsFile(presentationId, instructionsObj).then((res) => {
            if (res.data.errors) {
                console.log("ERROR", res.data.errors);
            } else {
                console.log("FILE RESPONSE", res);
                setInstructionsBody("");
                setInstructions(res.data.file);
                setIsNew(true);
                setIsNewInner(true);
                let isNewStateObj = {
                    isNew: true,
                    id: presentationId
                }
                setIsNewOuter(isNewStateObj);
                let presentationObj = {
                    isNew: true
                }
                updatePresentation(presentationId, presentationObj);
            }
        });
    }

    const toggleHitAudio = () => {
        if (isHitPlaying) {
            hitRef.current.pause();
            setHitPlaying(false);
        } else {
            if (missRef?.current) {
                missRef.current.pause();
            }
            if (gameOverRef?.current) {
                gameOverRef.current.pause();
            }
            setMissPlaying(false);
            setGameOverPlaying(false);

            hitRef.current.currentTime = 0;
            hitRef.current.play();
            setHitPlaying(true);
        }
    }

    const toggleMissAudio = () => {
        if (isMissPlaying) {
            missRef.current.pause();
            setMissPlaying(false);
        } else {
            if (hitRef?.current) {
                hitRef.current.pause();
            }
            if (gameOverRef?.current) {
                gameOverRef.current.pause();
            }
            setHitPlaying(false);
            setGameOverPlaying(false);

            missRef.current.currentTime = 0;
            missRef.current.play();
            setMissPlaying(true);
        }
    }

    const toggleGameOverAudio = () => {
        if (isGameOverPlaying) {
            gameOverRef.current.pause();
            setGameOverPlaying(false);
        } else {
            if (hitRef?.current) {
                hitRef.current.pause();
            }
            if (missRef?.current) {
                missRef.current.pause();
            }
            setHitPlaying(false);
            setMissPlaying(false);

            gameOverRef.current.currentTime = 0;
            gameOverRef.current.play();
            setGameOverPlaying(true);
        }
    }

    const handleChangeBackgroundColor = (color) => {
        setBackgroundColor(color);
        let backgroundStateObj = {
            backgroundColor: color,
            id: presentationId
        }
        setBackgroundColorOuter(backgroundStateObj);
        setBackgroundColorInner(color);
        if (!isNew) {
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
        }
        let backgroundObj = {
            backgroundColor: color,
            isNew: true
        }
        updatePresentation(presentationId, backgroundObj);
    }

    const handleChangeTextColor = (color) => {
        setTextColor(color);
        let textStateObj = {
            textColor: color,
            id: presentationId
        }
        setTextColorOuter(textStateObj);
        setTextColorInner(color);
        if (!isNew) {
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
        }
        let textObj = {
            textColor: color,
            isNew: true
        }
        updatePresentation(presentationId, textObj);
    }

    const handleChangeAlertColor = (color) => {
        setAlertColor(color);
        let alertStateObj = {
            alertColor: color,
            id: presentationId
        }
        setAlertColorOuter(alertStateObj);
        setAlertColorInner(color);
        if (!isNew) {
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
        }
        let alertObj = {
            alertColor: color,
            isNew: true
        }
        updatePresentation(presentationId, alertObj);
    }

    const uploadFile = (event, type) => {
        handleShowInstructionsModal();
        let file = event.target.files[0];
        let formData = new FormData();
        formData.append('file', file);
        createFile(presentationId, type, formData).then((res) => {
            if (res.data.errors) {
                console.log("ERROR", res.data.errors);
            } else {
                console.log("FINAL FILE", res.data.file);
                if (type === 'instructions') {
                    setInstructions(res.data.file);
                } else if (type === 'background') {
                    setBackground(res.data.file);
                } else if (type === 'logo') {
                    setLogo(res.data.file);
                } else if (type === 'active') {
                    setActive(res.data.file);
                } else if (type === 'inactive') {
                    setInactive(res.data.file);
                } else if (type === 'hit') {
                    setHit(res.data.file);
                } else if (type === 'miss') {
                    setMiss(res.data.file);
                } else if (type === 'gameover') {
                    setGameOver(res.data.file);
                }
                setIsNew(true);
                setIsNewInner(true);
                let isNewStateObj = {
                    isNew: true,
                    id: presentationId
                }
                setIsNewOuter(isNewStateObj);
                let presentationObj = {
                    isNew: true
                }
                updatePresentation(presentationId, presentationObj);
            }
        });
    }

    const handleDeleteFile = (type, id) => {
        deleteFile(presentationId, id).then((res) => {
            if (type === 'instructions') {
                setInstructions(null);
            } else if (type === 'background') {
                setBackground(null);
            } else if (type === 'logo') {
                setLogo(null);
            } else if (type === 'active') {
                setActive(null);
            } else if (type === 'inactive') {
                setInactive(null);
            } else if (type === 'hit') {
                setHit(null);
            } else if (type === 'miss') {
                setMiss(null);
            } else if (type === 'gameover') {
                setGameOver(null);
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            let presentationObj = {
                isNew: true
            }
            updatePresentation(presentationId, presentationObj);
        });
    }

    const handleUpdateTitle = (title) => {
        console.log("TITLE", title);
        if (gameTitle.length < 17) {
            setGameTitle(title.toUpperCase());
            let titleStateObj = {
                gameTitle: title.toUpperCase(),
                id: presentationId
            }
            setGameTitleOuter(titleStateObj);
            setGameTitleInner(title.toUpperCase());
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            let titleObj = {
                gameTitle: title.toUpperCase().trim(),
                isNew: true
            }
            updatePresentation(presentationId, titleObj);
        }
    }

    const handleIncrementScore = () => {
        if (Number(scoreIncrement) < 999) {
            setScoreIncrement(Number(scoreIncrement) + 1);
            setScoreIncrementInner(Number(scoreIncrement) + 1);
            let scoreIncrementStateObj = {
                scoreIncrement: Number(scoreIncrement) + 1,
                id: presentationId
            }
            setScoreIncrementOuter(scoreIncrementStateObj);
            let presentationObj = {
                scoreIncrement: Number(scoreIncrement) + 1,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const handleIncrementTimer = () => {
        if (Number(timerLength) < 999) {
            setTimerLength(Number(timerLength) + 1);
            setTimerLengthInner(Number(timerLength) + 1);
            let timerLengthStateObj = {
                timerLength: Number(timerLength) + 1,
                id: presentationId
            }
            setTimerLengthOuter(timerLengthStateObj);
            let presentationObj = {
                timerLength: Number(timerLength) + 1,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const handleDecrementScore = () => {
        if (Number(scoreIncrement) > 1) {
            setScoreIncrement(Number(scoreIncrement) - 1);
            setScoreIncrementInner(Number(scoreIncrement) - 1);
            let scoreIncrementStateObj = {
                scoreIncrement: Number(scoreIncrement) - 1,
                id: presentationId
            }
            setScoreIncrementOuter(scoreIncrementStateObj);
            let presentationObj = {
                scoreIncrement: Number(scoreIncrement) - 1,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const handleDecrementTimer = () => {
        if (Number(timerLength) > 1) {
            setTimerLength(Number(timerLength) - 1);
            setTimerLengthInner(Number(timerLength) - 1);
            let timerLengthStateObj = {
                timerLength: Number(timerLength) - 1,
                id: presentationId
            }
            setTimerLengthOuter(timerLengthStateObj);
            let presentationObj = {
                timerLength: Number(timerLength) - 1,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const handleChangeTimer = (time) => {
        if (Number(time) > 0 && Number(time) < 1000) {
            setTimerLength(Number(time));
            setTimerLengthInner(Number(time));
            let timerLengthStateObj = {
                timerLength: Number(time),
                id: presentationId
            }
            setTimerLengthOuter(timerLengthStateObj);
            let presentationObj = {
                timerLength: time,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const handleChangeScore = (score) => {
        if (Number(score) > 0 && Number(score) < 1000) {
            setScoreIncrement(Number(score));
            setScoreIncrementInner(Number(score));
            let scoreIncrementStateObj = {
                scoreIncrement: Number(score),
                id: presentationId
            }
            setScoreIncrementOuter(scoreIncrementStateObj);
            let presentationObj = {
                scoreIncrement: score,
                isNew: true
            }
            setIsNew(true);
            setIsNewInner(true);
            let isNewStateObj = {
                isNew: true,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            updatePresentation(presentationId, presentationObj);
        }
    }

    const downloadFile = () => {
        if (isNew && determineIfPresentationFinished()) {
            setIsNew(false);
            setIsNewInner(false);
            let isNewStateObj = {
                isNew: false,
                id: presentationId
            }
            setIsNewOuter(isNewStateObj);
            setDownloading(true);
            buildFile(presentationId, companyId, companyName).then((buildRes) => {
                // console.log("BUILD RES", buildRes);
                beginDownload(presentationId).then((downloadRes) => {
                    // console.log("DOWNLOAD RES", downloadRes);
                    // console.log("URL", downloadRes.request.responseURL);
                    // window.open(downloadRes.request.responseURL, 'myIframe');
                    let nowTime = new Date();
                    setLastUpdated(nowTime);
                    setLastUpdatedInner(nowTime);
                    let lastUpdatedStateObj = {
                        lastUpdated: nowTime,
                        id: presentationId
                    }
                    setLastUpdatedOuter(lastUpdatedStateObj);
                    setHasBeenUploaded(true);
                    setHasBeenUploadedInner(true);
                    let hasBeenUploadedStateObj = {
                        hasBeenUploaded: true,
                        id: presentationId
                    }
                    setHasBeenUploadedOuter(hasBeenUploadedStateObj);
                    let presentationObj = {
                        isNew: false,
                        lastUpdated: nowTime,
                        hasBeenUploaded: true
                    }
                    updatePresentation(presentationId, presentationObj).then((updateRes) => {
                        setShowCheck(true);
                        setTimeout(() => {
                            setShowCheck(false);
                            setDownloading(false);
                            setShowIU2Message(true);
                            setTimeout(() => {
                                setShowIU2Message(false);
                            }, 8000);
                        }, 4000);
                    });
                    // getADV(presentationName).then((advRes) => {
                    //     console.log("ADV RES", advRes);
                    // })
                });
            });
        }
    }

    const determineIfPresentationFinished = () => {
        if (title.length && background && logo && active) {
            return true;
        } else {
            return false;
        }
    }

    const parseDate = (date) => {
        // console.log("DATE", date)
        if (!isNaN(date)) {
            return (
                <p>
                    Last uploaded {date.toDateString()} at {date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours()}:{date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes()}
                </p>
            )
        } else {
            return (
                <p>No uploads this session.</p>
            )
        }

    }

    let hitAudioElement = isHitPlaying ? (
        <>
            <div className='uploaded-image'>
                <VolumeUpRoundedIcon
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleHitAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{hit ? decodeURIComponent(hit.name) : 'hit-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('hit', hit.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    ) : (
        <>
            <div className='uploaded-image'>
                <VolumeMuteRoundedIcon
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleHitAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{hit ? decodeURIComponent(hit.name) : 'hit-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('hit', hit.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    );

    let missAudioElement = isMissPlaying ? (
        <>
            <div className='uploaded-image'>
                <VolumeUpRoundedIcon
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleMissAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{miss ? decodeURIComponent(miss.name) : 'miss-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('miss', miss.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    ) : (
        <>
            <div className='uploaded-image'>
                <VolumeMuteRoundedIcon 
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleMissAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{miss ? decodeURIComponent(miss.name) : 'miss-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('miss', miss.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    );

    let gameOverAudioElement = isGameOverPlaying ? (
        <>
            <div className='uploaded-image'>
                <VolumeUpRoundedIcon
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleGameOverAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{gameOver ? decodeURIComponent(gameOver.name) : 'gameover-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('gameover', gameOver.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    ) : (
        <>
            <div className='uploaded-image'>
                <VolumeMuteRoundedIcon 
                    style={{color: '#3b3b3b', cursor: 'pointer'}}
                    onClick={() => toggleGameOverAudio()}
                />
            </div>
            <div className='uploaded-title'>
                <p>{gameOver ? decodeURIComponent(gameOver.name) : 'gameover-sound.mp3'}</p>
            </div>
            <div className='delete' onClick={() => handleDeleteFile('gameover', gameOver.id)}>
                <HighlightOffRoundedIcon />
            </div>
        </>
    );

    return (
        <StyledContentContainer 
            buttonHovered={buttonHovered} 
            isNew={isNew} 
            isFinished={determineIfPresentationFinished()} 
        >
            <div 
                className='background-layer'
                style={{
                    backgroundImage: `url(${f2b})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
            />
            <div 
                className='instructions-modal-background' 
                onClick={() => handleShowInstructionsModal()}
                style={{
                    opacity: showInstructionsModal ? 1 : 0,
                    visibility: showInstructionsModal ? 'visible' : 'hidden',
                    display: showInstructionsModal ? 'flex' : 'none'
                }}
            />
            <div 
                className='color-modal-background' 
                onClick={() => handleShowColorModal()}
                style={{
                    opacity: showColorModal ? 1 : 0,
                    visibility: showColorModal ? 'visible' : 'hidden',
                    display: showColorModal ? 'flex' : 'none'
                }}
            />
            <div 
                className='color-modal'
                style={{
                    opacity: showColorModal ? 1 : 0,
                    visibility: showColorModal ? 'visible' : 'hidden',
                    display: showColorModal ? 'flex' : 'none'
                }}
            >
                <div className='close-modal' onClick={() => handleShowColorModal()}>
                    <CloseRoundedIcon />
                </div>
                <div className='center-band'>
                    <div className='color-band'>
                        <div className='color-box'>
                            <HexColorPicker color={backgroundColor} onChange={handleChangeBackgroundColor} />
                            <HexColorInput color={backgroundColor} onChange={handleChangeBackgroundColor} />
                        </div>
                        <div className='text-box'>
                            <p>Background Color</p>
                        </div>
                    </div>
                    <div className='color-band'>
                        <div className='color-box'>
                            <HexColorPicker color={textColor} onChange={handleChangeTextColor} />
                            <HexColorInput color={textColor} onChange={handleChangeTextColor} />
                        </div>
                        <div className='text-box'>
                            <p>Text Color</p>
                        </div>
                    </div>
                    <div className='color-band'>
                        <div className='color-box'>
                            <HexColorPicker color={alertColor} onChange={handleChangeAlertColor} />
                            <HexColorInput color={alertColor} onChange={handleChangeAlertColor} />
                        </div>
                        <div className='text-box'>
                            <p>Timer Alert Color</p>
                        </div>
                    </div>
                </div>
            </div>
            <div 
                className='instructions-modal'
                style={{
                    opacity: showInstructionsModal ? 1 : 0,
                    visibility: showInstructionsModal ? 'visible' : 'hidden',
                    display: showInstructionsModal ? 'flex' : 'none'
                }}
            >
                <div className='close-modal' onClick={() => handleShowInstructionsModal()}>
                    <CloseRoundedIcon />
                </div>
                <div className='manual-box'>
                    <div className='image'>
                        {/* <input className='file-name' placeholder="File Name" value={instructionsTitle} onChange={(event) => handleSetInstructionsTitle(event)} type="text"/> */}
                        <textarea className='file-body' value={instructionsBody} onChange={(event) => handleSetInstructionsBody(event)} type="text"/>
                        <div className='upload-button' onClick={() => handleInstructionsUpload()}>
                            <UploadRoundedIcon />
                            <p>Upload</p>
                        </div>
                        <div className='instructions'>
                            <p>Input Text</p>
                        </div>
                    </div>
                </div>
                <div className='or'>
                    <p>or</p>
                </div>
                <div className='upload-instructions'>
                    <div className='image'>
                        <label className='image-inner' for="instructions">
                            <FileUploadOutlinedIcon />
                            <p>Text File</p>
                        </label>
                        <StyledInput 
                            type="file" 
                            multiple={false} 
                            accept=".txt" 
                            onChange={(event) => {
                                uploadFile(event, 'instructions');
                            }} 
                            name="instructions" 
                            id="instructions" 
                        />
                        <div className='text'>
                            <p>Upload File</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='presentation-select'>
                <FolderRoundedIcon onClick={() => {
                    clearPresentation();
                    resetView();
                    setDownloading(false);
                    setShowCheck(false);
                    history.push('/presentations');
                }}/>
                <ExitToAppRoundedIcon onClick={() => {
                    resetContent();
                    resetAuth();
                    resetView();
                    setDownloading(false);
                    setShowCheck(false);
                    history.push('/');
                }}/>
            </div>
            <div className='preview-container'>
                <div 
                    className='preview-box'
                    style={{
                        backgroundColor: backgroundColor ? backgroundColor : 'rgb(25, 25, 36)'
                    }}
                >
                    <div className='color-select-box' onClick={() => handleShowColorModal()}>
                        <p>Color Select</p>
                        <section/>
                    </div>
                    <div 
                        className='top-banner'
                        style={{
                            backgroundColor: backgroundColor ? backgroundColor : 'rgb(25, 25, 36)'
                        }}
                    >
                        <section>
                            <div className='inner-box'>
                                <div className='top'>
                                    <p style={{color: textColor ? textColor : 'white'}}>Score</p>
                                </div>
                                <div className='center'>
                                    <p style={{color: textColor ? textColor : 'white'}}>0</p>
                                </div>
                                <div className='bottom'>
                                    <p style={{color: textColor ? textColor : 'white'}}>Points</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='inner-box'>
                                <div className='top'>
                                    <p style={{color: textColor ? textColor : 'white'}}>High Score</p>
                                </div>
                                <div className='center'>
                                    <p style={{color: textColor ? textColor : 'white'}}>0</p>
                                </div>
                                <div className='bottom'>
                                    <p style={{color: textColor ? textColor : 'white'}}>Points</p>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className='inner-box'>
                                <div className='top'>
                                    <p style={{color: textColor ? textColor : 'white'}}>Timer</p>
                                </div>
                                <div className='center timer-center'>
                                    <p style={{color: alertColor ? alertColor : 'red'}}>{timerLength}</p>
                                </div>
                                <div className='bottom'>
                                    <p style={{color: textColor ? textColor : 'white'}}>Seconds</p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div 
                        className='image-container'
                        style={{
                            backgroundImage: `url('${background?.url ? background.url : ""}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center'
                        }}
                    >
                        <div className='icon-container'>
                            <div className='single-icon' style={{visibility: logo?.url ? 'visible' : 'hidden'}}>
                                <div className='image'>
                                    <img src={logo?.url ? logo.url : ''} alt='logo-inner'/>
                                </div>
                                <div className='title'>
                                    <p>Logo</p>
                                </div>
                            </div>
                            <div className='single-icon' style={{visibility: active?.url ? 'visible' : 'hidden'}}>
                                <div className='image'>
                                    <img src={active?.url ? active.url : ''} alt='logo-inner'/>
                                </div>
                                <div className='title'>
                                    <p>Active</p>
                                </div>
                            </div>
                            <div className='single-icon' style={{visibility: inactive?.url ? 'visible' : 'hidden'}}>
                                <div className='image'>
                                    <img src={inactive?.url ? inactive.url : ''} alt='logo-inner'/>
                                </div>
                                <div className='title'>
                                    <p>Inactive</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div 
                        className='bottom-banner'
                        style={{
                            backgroundColor: backgroundColor ? backgroundColor : 'rgb(25, 25, 36)'
                        }}
                    >
                        <p style={{color: textColor ? textColor : 'white'}}>{gameTitle?.length ? gameTitle : 'Agility Game'}</p>
                    </div>
                </div>
            </div>
            <div className='upload-container'>
                <div className='inner text-container'>
                    <div className='input-box' style={{justifyContent: 'flex-end'}}>
                        <div className='top-and-bottom top'>
                            <div className='presentation-name'>
                                <p>{title}</p>
                            </div>
                            <input
                                placeholder='Agility Game'
                                value={gameTitle}
                                onChange={(event) => handleUpdateTitle(event.target.value)}
                                type='text'
                            />
                        </div>
                        <div className='top-and-bottom bottom'>
                            <p>Game Title</p>
                        </div>
                    </div>
                    <div className='integer-box'>
                        <div className='integer'>
                            <input 
                                value={timerLength}
                                type="text"
                                onChange={(event) => handleChangeTimer(event.target.value)}
                            />
                        </div>
                        <div className='tip-box'>
                            <p>seconds</p>
                        </div>
                        <div className='controller'>
                            <div className='incrementer' onClick={() => handleDecrementTimer()} style={{
                                backgroundColor: Number(timerLength) > 1 ? 'rgb(7,104,176)' : '#888888',
                            }}>
                                <ArrowBackRounded/>
                            </div>
                            <div className='incrementer' onClick={() => handleIncrementTimer()} style={{backgroundColor: Number(timerLength) < 999 ? 'rgb(7,104,176)' : '#888888'}}>
                                <ArrowForwardRoundedIcon/>
                            </div>
                        </div>
                        <div className='title'>
                            <p>Timer Length</p>
                        </div>
                    </div>
                    <div className='integer-box'>
                        <div className='integer'>
                            <input 
                                value={scoreIncrement}
                                type="text"
                                onChange={(event) => handleChangeScore(event.target.value)}
                            />
                        </div>
                        <div className='tip-box'>
                            <p>points</p>
                        </div>
                        <div className='controller'>
                            <div className='incrementer' onClick={() => handleDecrementScore()} style={{backgroundColor: Number(scoreIncrement) > 1 ? 'rgb(7,104,176)' : '#888888'}}>
                                <ArrowBackRounded/>
                            </div>
                            <div className='incrementer' onClick={() => handleIncrementScore()} style={{backgroundColor: Number(scoreIncrement) < 999 ? 'rgb(7,104,176)' : '#888888'}}>
                                <ArrowForwardRoundedIcon/>
                            </div>
                        </div>
                        <div className='title'>
                            <p>Score Increment</p>
                        </div>
                    </div>
                </div>
                <div className='inner image-container'>
                    <div className='upload-box'>
                        <div className='image'>
                            {!background ? (
                                <label className='image-inner' for="background">
                                    <FileUploadOutlinedIcon />
                                    <p>Image File 1080x1920</p>
                                </label>
                            ) : (
                                <>
                                    <div className='uploaded-image'>
                                        <img 
                                            alt="background"
                                            src={background.url}
                                        />
                                    </div>
                                    <div className='uploaded-title'>
                                        <p>{decodeURIComponent(background.name)}</p>
                                    </div>
                                    <div className='delete' onClick={() => handleDeleteFile('background', background.id)}>
                                        <HighlightOffRoundedIcon />
                                    </div>
                                </>
                            )}
                            <StyledInput
                                type="file"
                                multiple={false}
                                accept=".jpg, .png"
                                onChange={(event) => {
                                    uploadFile(event, 'background');
                                }} 
                                name="background"
                                id="background"
                            />
                        </div>
                        <div className='text'>
                            <p>Background Image</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!logo ? (
                                <label className='image-inner' for="logo">
                                    <FileUploadOutlinedIcon />
                                    <p>Image File</p>
                                </label>
                            ) : (
                                <>
                                    <div className='uploaded-image'>
                                        <img 
                                            alt="logo"
                                            src={logo.url}
                                        />
                                    </div>
                                    <div className='uploaded-title'>
                                        <p>{decodeURIComponent(logo.name)}</p>
                                    </div>
                                    <div className='delete' onClick={() => handleDeleteFile('logo', logo.id)}>
                                        <HighlightOffRoundedIcon />
                                    </div>
                                </>
                            )}
                            <StyledInput
                                type="file"
                                multiple={false}
                                accept=".jpg, .png"
                                onChange={(event) => {
                                    uploadFile(event, 'logo');
                                }} 
                                name="logo"
                                id="logo" 
                            />
                        </div>
                        <div className='text'>
                            <p>Logo</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!active ? (
                                <label className='image-inner' for="active">
                                    <FileUploadOutlinedIcon />
                                    <p>Image File</p>
                                </label>
                            ) : (
                                <>
                                    <div className='uploaded-image'>
                                        <img 
                                            alt="active"
                                            src={active.url}
                                        />
                                    </div>
                                    <div className='uploaded-title'>
                                        <p>{decodeURIComponent(active.name)}</p>
                                    </div>
                                    <div className='delete' onClick={() => handleDeleteFile('active', active.id)}>
                                        <HighlightOffRoundedIcon />
                                    </div>
                                </>
                            )}
                            <StyledInput
                                type="file" 
                                multiple={false} 
                                accept=".jpg, .png"
                                onChange={(event) => {
                                    uploadFile(event, 'active');
                                }} 
                                name="active"
                                id="active"
                            />
                        </div>
                        <div className='text'>
                            <p>Active Tile</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!inactive ? (
                                <label className='image-inner' for="inactive">
                                    <FileUploadOutlinedIcon />
                                    <p>Image File</p>
                                </label>
                            ) : (
                                <>
                                    <div className='uploaded-image'>
                                        <img 
                                            alt="inactive"
                                            src={inactive.url}
                                        />
                                    </div>
                                    <div className='uploaded-title'>
                                        <p>{decodeURIComponent(inactive.name)}</p>
                                    </div>
                                    <div className='delete' onClick={() => handleDeleteFile('inactive', inactive.id)}>
                                        <HighlightOffRoundedIcon />
                                    </div>
                                </>
                            )}
                            <StyledInput
                                type="file"
                                multiple={false}
                                accept=".jpg, .png"
                                onChange={(event) => {
                                    uploadFile(event, 'inactive');
                                }} 
                                name="inactive"
                                id="inactive"
                            />
                        </div>
                        <div className='text'>
                            <p>Inactive Tile</p>
                        </div>
                    </div>
                </div>
                <div className='inner sound-container'>
                    <div className='upload-box'>
                        <div className='image'>
                            {!hit ? (
                                <label className='image-inner' for="hit">
                                    <FileUploadOutlinedIcon />
                                    <p>MP3 File</p>
                                </label>
                            ) : (
                                <>
                                    {hitAudioElement}
                                    <audio autoplay ref={hitRef}>
                                        <source src={hit.url}/>
                                    </audio>
                                </>
                            )}
                            <StyledInput 
                                type="file"
                                multiple={false}
                                accept=".wav, .mp3, .wma, flac"
                                onChange={(event) => {
                                    uploadFile(event, 'hit');
                                }} 
                                name="hit"
                                id="hit"
                            />
                        </div>
                        <div className='text'>
                            <p>Hit Sound Effect</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!miss ? (
                                <label className='image-inner' for="miss">
                                    <FileUploadOutlinedIcon />
                                    <p>MP3 File</p>
                                </label>
                            ) : (
                                <>
                                {missAudioElement}
                                <audio autoplay ref={missRef}>
                                    <source src={miss.url}/>
                                </audio>
                                </>
                            )}
                            <StyledInput
                                type="file"
                                multiple={false}
                                accept=".wav, .mp3, .wma, flac"
                                onChange={(event) => {
                                    uploadFile(event, 'miss');
                                }} 
                                name="miss"
                                id="miss" 
                            />
                        </div>
                        <div className='text'>
                            <p>Miss Sound Effect</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!gameOver ? (
                                <label className='image-inner' for="gameover">
                                    <FileUploadOutlinedIcon />
                                    <p>MP3 File</p>
                                </label>
                            ) : (
                                <>
                                {gameOverAudioElement}
                                <audio autoplay ref={gameOverRef}>
                                    <source src={gameOver.url}/>
                                </audio>
                                </>
                            )}
                            <StyledInput
                                type="file"
                                multiple={false}
                                accept=".wav, .mp3, .wma, flac"
                                onChange={(event) => {
                                    uploadFile(event, 'gameover');
                                }} 
                                name="gameover"
                                id="gameover"
                            />
                        </div>
                        <div className='text'>
                            <p>Game Over Sound Effect</p>
                        </div>
                    </div>
                    <div className='upload-box'>
                        <div className='image'>
                            {!instructions ? (
                                <div className='image-inner' onClick={() => handleShowInstructionsModal()}>
                                    <FileUploadOutlinedIcon />
                                    <p>Text File</p>
                                </div>
                            ) : (
                                <>
                                    <div className='uploaded-image'>
                                        <NoteRoundedIcon 
                                            style={{
                                                color: '#3b3b3b'
                                            }}
                                        />
                                    </div>
                                    <div className='uploaded-title'>
                                        <p>{decodeURIComponent(instructions.name)}</p>
                                    </div>
                                    <div className='delete' onClick={() => handleDeleteFile('instructions', instructions.id)}>
                                        <HighlightOffRoundedIcon />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className='text'>
                            <p>Game Instructions</p>
                        </div>
                    </div>
                </div>
                <div className='inner save-container'>
                    <div className='iu2-notification' style={{opacity: showIU2Message ? 1 : 0}}>
                        <p>Presentation uploaded - please check IU2</p>
                    </div>
                    {determineIfPresentationFinished() ? null : <ReactTooltip />}
                    <div 
                        className='save-button'
                        onPointerEnter={() => setButtonHovered(true)}
                        onPointerLeave={() => setButtonHovered(false)}
                        onClick={() => downloadFile()}
                        data-tip="Background, Logo, and Active Tile are needed."
                    >
                        {downloading ? 
                            showCheck ? (
                                <CheckCircleOutlineRoundedIcon />
                            ) : (
                                <div className='downloading-spinner'>
                                    <Spinner animation="border" role="status" variant="secondary">
                                        {/* <span className="sr-only">Loading...</span> */}
                                    </Spinner>
                                </div>
                            )
                        : 
                            <UploadRoundedIcon />
                        }
                        <p>
                            {determineIfPresentationFinished() === false
                                ? 
                                    'Incomplete' 
                                : 
                                    isNew 
                                        ?
                                            'Publish'
                                        : 
                                            downloading 
                                                ?
                                                    'Publishing...'
                                                :
                                                    'Published'
                            }
                        </p>
                    </div>
                    <div className='updated-container'>
                        {hasBeenUploaded ? parseDate(lastUpdated) : <p>Presentation has not been uploaded.</p>}
                    </div>
                </div>
            </div>
        </StyledContentContainer>
    )

}

export default Content;