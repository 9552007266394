/* eslint-disable import/no-anonymous-default-export */
import {
    SET_TITLE,
    SET_INSTRUCTIONS,
    SET_BACKGROUND,
    SET_LOGO,
    SET_ACTIVE,
    SET_INACTIVE,
    SET_HIT,
    SET_MISS,
    SET_GAME_OVER,
    SET_PRESENTATIONS,
    SET_CURRENT_PRESENTATION,
    SET_PRESENTATION_ID,
    SET_FILES,
    RESET_CONTENT,
    CLEAR_PRESENTATION,
    CLEAR_ALL_PRESENTATIONS,
    UPDATE_PRESENTATION_TITLE_INNER,
    UPDATE_PRESENTATION_TITLE_OUTER,
    SET_AVAILABLE_BACKGROUNDS,
    CLEAR_BACKGROUNDS,
    SET_SCORE_INCREMENT,
    SET_TIMER_LENGTH,
    SET_IS_NEW,
    SET_LAST_UPDATED,
    SET_HAS_BEEN_UPLOADED,
    UPDATE_IS_NEW_INNER,
    UPDATE_IS_NEW_OUTER,
    UPDATE_HAS_BEEN_UPLOADED_INNER,
    UPDATE_HAS_BEEN_UPLOADED_OUTER,
    UPDATE_LAST_UPDATED_INNER,
    UPDATE_LAST_UPDATED_OUTER,
    SET_SCORE_INCREMENT_INNER,
    SET_SCORE_INCREMENT_OUTER,
    SET_TIMER_LENGTH_INNER,
    SET_TIMER_LENGTH_OUTER,
    SET_GAME_TITLE,
    SET_GAME_TITLE_INNER,
    SET_GAME_TITLE_OUTER,
    SET_USED_NAMES,
    SET_BACKGROUND_COLOR,
    SET_BACKGROUND_COLOR_INNER,
    SET_BACKGROUND_COLOR_OUTER,
    SET_TEXT_COLOR,
    SET_TEXT_COLOR_INNER,
    SET_TEXT_COLOR_OUTER,
    SET_ALERT_COLOR,
    SET_ALERT_COLOR_INNER,
    SET_ALERT_COLOR_OUTER
} from '../constants/actionTypes';

const initialState = {
    title: '',
    gameTitle: '',
    instructions: null,
    background: null,
    logo: null,
    active: null,
    inactive: null,
    hit: null,
    miss: null,
    gameOver: null,
    presentations: [],
    currentPresentation: {},
    presentationId: '',
    files: [],
    availableBackgrounds: [],
    scoreIncrement: 1,
    timerLength: 30,
    isNew: true,
    lastUpdated: '',
    hasBeenUploaded: false,
    usedNames: [],
    backgroundColor: '',
    textColor: '',
    alertColor: ''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_TITLE:
            return {
                ...state,
                title: action.payload
            };
        case SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.payload
            };
        case SET_BACKGROUND_COLOR_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    backgroundColor: action.payload
                }
            };
        case SET_BACKGROUND_COLOR_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        backgroundColor: action.payload.backgroundColor, 
                    } : presentation
                )    
            }
        case SET_TEXT_COLOR:
            return {
                ...state,
                textColor: action.payload
            };
        case SET_TEXT_COLOR_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    textColor: action.payload
                }
            };
        case SET_TEXT_COLOR_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        textColor: action.payload.textColor, 
                    } : presentation
                )    
            }
        case SET_ALERT_COLOR:
            return {
                ...state,
                alertColor: action.payload
            };
        case SET_ALERT_COLOR_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    alertColor: action.payload
                }
            };
        case SET_ALERT_COLOR_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        alertColor: action.payload.alertColor, 
                    } : presentation
                )    
            }
        case SET_GAME_TITLE:
            return {
                ...state,
                gameTitle: action.payload
            };
        case SET_GAME_TITLE_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    gameTitle: action.payload
                }
            };
        case SET_GAME_TITLE_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        gameTitle: action.payload.gameTitle, 
                    } : presentation
                )    
            }
        case SET_TIMER_LENGTH_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    timerLength: action.payload
                }
            };
        case SET_TIMER_LENGTH_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        timerLength: action.payload.timerLength, 
                    } : presentation
                )    
            }
        case SET_SCORE_INCREMENT_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    scoreIncrement: action.payload
                }
            };
        case SET_SCORE_INCREMENT_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        scoreIncrement: action.payload.scoreIncrement, 
                    } : presentation
                )    
            }
        case UPDATE_LAST_UPDATED_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    lastUpdated: action.payload
                }
            };
        case UPDATE_LAST_UPDATED_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        lastUpdated: action.payload.lastUpdated, 
                    } : presentation
                )    
            }
        case UPDATE_IS_NEW_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    isNew: action.payload
                }
            };
        case UPDATE_IS_NEW_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        isNew: action.payload.isNew, 
                    } : presentation
                )    
            }
        case UPDATE_HAS_BEEN_UPLOADED_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    hasBeenUploaded: action.payload
                }
            };
        case UPDATE_HAS_BEEN_UPLOADED_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        hasBeenUploaded: action.payload.hasBeenUploaded, 
                    } : presentation
                )    
            }
        case UPDATE_PRESENTATION_TITLE_INNER:
            return {
                ...state,
                currentPresentation: {
                    ...state.currentPresentation,
                    name: action.payload
                }
            };
        case UPDATE_PRESENTATION_TITLE_OUTER:
            return {
                ...state,
                presentations: state?.presentations?.map(    
                    (presentation) => presentation?.id === action.payload.id ? {
                        ...presentation,
                        name: action.payload.name, 
                    } : presentation
                )    
            }
        case SET_INSTRUCTIONS:
            return {
                ...state,
                instructions: action.payload
            };
        case SET_BACKGROUND:
            return {
                ...state,
                background: action.payload
            };
        case SET_LOGO:
            return {
                ...state,
                logo: action.payload
            };
        case SET_ACTIVE:
            return {
                ...state,
                active: action.payload
            };
        case SET_INACTIVE:
            return {
                ...state,
                inactive: action.payload
            };
        case SET_HIT:
            return {
                ...state,
                hit: action.payload
            };
        case SET_MISS:
            return {
                ...state,
                miss: action.payload
            };
        case SET_GAME_OVER:
            return {
                ...state,
                gameOver: action.payload
            };
        case SET_PRESENTATIONS:
            return {
                ...state,
                presentations: state.presentations.concat(action.payload)
            };
        case SET_CURRENT_PRESENTATION:
            return {
                ...state,
                currentPresentation: action.payload
            };
        case SET_PRESENTATION_ID:
            return {
                ...state,
                presentationId: action.payload
            };
        case SET_FILES:
            return {
                ...state,
                files: state.files.concat(action.payload)
            };
        case SET_AVAILABLE_BACKGROUNDS:
            return {
                ...state,
                availableBackgrounds: state.availableBackgrounds.concat(action.payload)
            };
        case SET_USED_NAMES:
            return {
                ...state,
                usedNames: state.usedNames.concat(action.payload)
            };
        case SET_SCORE_INCREMENT:
            return {
                ...state,
                scoreIncrement: action.payload
            };
        case SET_TIMER_LENGTH:
            return {
                ...state,
                timerLength: action.payload
            };
        case SET_IS_NEW:
            return {
                ...state,
                isNew: action.payload
            };
        case SET_LAST_UPDATED:
            return {
                ...state,
                lastUpdated: action.payload
            };
        case SET_HAS_BEEN_UPLOADED:
            return {
                ...state,
                hasBeenUploaded: action.payload
            };
        case RESET_CONTENT:
            return {
                ...state,
                title: '',
                instructions: null,
                background: null,
                logo: null,
                active: null,
                inactive: null,
                hit: null,
                miss: null,
                gameOver: null,
                presentations: [],
                currentPresentation: {},
                presentationId: '',
                files: [],
                availableBackgrounds: [],
                scoreIncrement: 1,
                timerLength: 30,
                isNew: true,
                lastUpdated: '',
                hasBeenUploaded: false,
                gameTitle: '',
                backgroundColor: '',
                textColor: '',
                alertColor: ''
            };
        case CLEAR_PRESENTATION:
            return {
                ...state,
                title: '',
                instructions: null,
                background: null,
                logo: null,
                active: null,
                inactive: null,
                hit: null,
                miss: null,
                gameOver: null,
                currentPresentation: {},
                presentationId: '',
                files: [],
                scoreIncrement: 1,
                timerLength: 30,
                isNew: true,
                lastUpdated: '',
                hasBeenUploaded: false,
                gameTitle: '',
                backgroundColor: '',
                textColor: '',
                alertColor: ''
            };
        case CLEAR_BACKGROUNDS:
            return {
                ...state,
                availableBackgrounds: []
            };
        case CLEAR_ALL_PRESENTATIONS:
            return {
                ...state,
                presentations: []
            };
        default:
            return state;
    }
};