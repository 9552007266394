import { connect } from 'react-redux';
import Presentations from './Presentations';
import { 
  setCurrentPresentation,
  setPresentationId,
  setPresentations,
  setFiles,
  setInstructions,
  setBackground,
  setLogo,
  setActive,
  setInactive,
  setHit,
  setMiss,
  setGameOver,
  setTitle,
  resetContent,
  resetAuth,
  resetView,
  setAvailableBackgrounds,
  clearBackgrounds,
  clearAllPresentations,
  setScoreIncrement,
  setTimerLength,
  setLastUpdated,
  setIsNew,
  setHasBeenUploaded,
  setIsAuth,
  setGameTitle,
  setUsedNames,
  setCompanyName,
  setCompanyId,
  setCompanyUserFilter,
  setBackgroundColor,
  setTextColor,
  setAlertColor
} from '../../actions';

const mapStateToProps = (state) => ({
  isAuth: state.authReducer.isAuth,
  presentations: state.contentReducer.presentations,
  userId: state.authReducer.userId,
  companyId: state.authReducer.companyId,
  availableBackgrounds: state.contentReducer.availableBackgrounds,
  authToken: state.authReducer.authToken,
  usedNames: state.contentReducer.usedNames,
  userView: state.authReducer.userView,
  hiddenCompanyId: state.authReducer.hiddenCompanyId,
  companyName: state.authReducer.companyName,
  companyUserFilter: state.authReducer.companyUserFilter
});

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuth: (bool) => dispatch(setIsAuth(bool)),
    setCurrentPresentation: (presentation) => dispatch(setCurrentPresentation(presentation)),
    setPresentationId: (id) => dispatch(setPresentationId(id)),
    setPresentations: (presentations) => dispatch(setPresentations(presentations)),
    setFiles: (files) => dispatch(setFiles(files)),
    setInstructions: (path) => dispatch(setInstructions(path)),
    setBackground: (path) => dispatch(setBackground(path)),
    setLogo: (path) => dispatch(setLogo(path)),
    setActive: (path) => dispatch(setActive(path)),
    setInactive: (path) => dispatch(setInactive(path)),
    setHit: (path) => dispatch(setHit(path)),
    setMiss: (path) => dispatch(setMiss(path)),
    setGameOver: (path) => dispatch(setGameOver(path)),
    setTitle: (path) =>  dispatch(setTitle(path)),
    resetContent: () => dispatch(resetContent()),
    resetAuth: () => dispatch(resetAuth()),
    resetView: () => dispatch(resetView()),
    setAvailableBackgrounds: (backgroundObjects) => dispatch(setAvailableBackgrounds(backgroundObjects)),
    clearBackgrounds: () => dispatch(clearBackgrounds()),
    clearAllPresentations: () => dispatch(clearAllPresentations()),
    setScoreIncrement: (int) => dispatch(setScoreIncrement(int)),
    setTimerLength: (int) => dispatch(setTimerLength(int)),
    setIsNew: (bool) => dispatch(setIsNew(bool)),
    setLastUpdated: (date) => dispatch(setLastUpdated(date)),
    setHasBeenUploaded: (bool) => dispatch(setHasBeenUploaded(bool)),
    setGameTitle: (string) => dispatch(setGameTitle(string)),
    setUsedNames: (string) => dispatch(setUsedNames(string)),
    setCompanyName: (string) => dispatch(setCompanyName(string)),
    setCompanyId: (string) => dispatch(setCompanyId(string)),
    setCompanyUserFilter: (string) => dispatch(setCompanyUserFilter(string)),
    setBackgroundColor: (string) => dispatch(setBackgroundColor(string)),
    setTextColor: (string) => dispatch(setTextColor(string)),
    setAlertColor: (string) => dispatch(setAlertColor(string))
  };
};

const PresentationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Presentations);

export default PresentationsContainer;
