import axios from 'axios';


export function createSession() {
    const token = window.sessionStorage.getItem('token');
    return axios.create({
        validateStatus: function(){return true},
        baseURL: 'https://rb.f2bevents.com:3002',
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        },
    });
}


export const verifyUser = (userObj) => {
    const session = createSession();
    const response = session.post('/auth/verify', userObj);
    return response;
} 

export const getPresentations = (userId) => {
    const session = createSession();
    const response = session.get(`/presentations/${userId}`);
    return response;
}

export const getPresentationsByCompany = (companyId) => {
    const session = createSession();
    const response = session.get(`/presentations/byCompany/${companyId}`);
    return response;
}

export const createPresentation = (presentationData) => {
    const session = createSession();
    const response = session.post(`/presentations`, presentationData);
    return response;  
}

export const updatePresentation = (presentationId, presentationData) => {
    const session = createSession();
    const response = session.patch(`/presentations/${presentationId}`, presentationData);
    return response;
}

export const getFiles = (presentationId) => {
    const session = createSession();
    const response = session.get(`/files/getAll/${presentationId}`);
    return response;  
}

export const getFilesByType = (presentationId, fileType) => {
    const session = createSession();
    const response = session.get(`/files/getByType/${presentationId}/${fileType}`);
    return response;  
}

export const createFile = (presentationId, fileType, fileData) => {
    const session = createSession();
    const response = session.post(`/files/${presentationId}/${fileType}`, fileData);
    return response;  
}

export const createInstructionsFile = (presentationId, instructionsData) => {
    const session = createSession();
    const response = session.post(`/files/${presentationId}`, instructionsData);
    return response;
}

export const getPresentationsByFirestore = async(companyId, presentationName) => {
    const session = createSession();
    const response = await session.get(`/presentations/getFirestore/${companyId}/${presentationName}`);
    return response;
}

export const deleteFile = (presentationId, fileId) => {
    const session = createSession();
    const response = session.delete(`/files/${presentationId}/${fileId}`);
    return response;  
}

export const updateFile = (fileId, fileBody) => {
    const session = createSession();
    const response = session.patch(`/files/${fileId}`, fileBody);
    return response;  
}

export const buildFile = (presentationId, companyId, companyName) => {
    const session = createSession();
    const response = session.post(`/downloads/${presentationId}/${companyId}/${companyName}`);
    return response;  
}

export const beginDownload = (presentationId) => {
    const session = createSession();
    const response = session.get(`/downloads/${presentationId}`);
    return response;  
}

export const getADV = (presentationName) => {
    const session = createSession();
    const response = session.get(`aws/${presentationName}`);
    return response;
}
