import { 
    SET_MISS_PLAYING,
    SET_HIT_PLAYING,
    SET_GAME_OVER_PLAYING,
    RESET_VIEW
} from '../constants/actionTypes';

export function setMissPlaying(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_MISS_PLAYING,
            payload: bool
        });  
    }  
}; 

export function setHitPlaying(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_HIT_PLAYING,
            payload: bool
        });  
    }  
}; 

export function setGameOverPlaying(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_GAME_OVER_PLAYING,
            payload: bool
        });  
    }  
}; 

export function resetView() {  
    return dispatch => {  
        return dispatch({  
            type: RESET_VIEW
        });  
    }  
}; 