// Auth Reducer
export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const PATCH_USER = 'PATCH_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_USER = 'SET_USER';
export const RESET_AUTH = 'RESET_AUTH';
export const SET_COMPANY_ID = "SET_COMPANY_ID";
export const SET_COMPANY_NAME = "SET_COMPANY_NAME";
export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_HIDDEN_COMPANY_ID = "SET_HIDDEN_COMPANY_ID";
export const SET_USER_VIEW = "SET_USER_VIEW";
export const SET_COMPANY_USER_FILTER = "SET_COMPANY_USER_FILTER";

// Content Reducer
export const SET_PRESENTATIONS = 'SET_PRESENTATIONS';
export const SET_CURRENT_PRESENTATION = 'SET_CURRENT_PRESENTATION';
export const SET_PRESENTATION_ID = 'SET_PRESENTATION_ID';
export const SET_FILES = 'SET_FILES';
export const SET_TITLE = 'SET_TITLE';
export const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS';
export const SET_BACKGROUND = 'SET_BACKGROUND';
export const SET_LOGO = 'SET_LOGO';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_INACTIVE = 'SET_INACTIVE';
export const SET_HIT = 'SET_HIT';
export const SET_MISS = 'SET_MISS'
export const SET_GAME_OVER = 'SET_GAME_OVER';
export const SET_AVAILABLE_BACKGROUNDS = 'SET_AVAILABLE_BACKGROUNDS';
export const SET_USED_NAMES = "SET_USED_NAMES";
export const CLEAR_BACKGROUNDS = 'CLEAR_BACKGROUNDS';
export const RESET_CONTENT = 'RESET_CONTENT';
export const CLEAR_PRESENTATION = 'CLEAR_PRESENTATION';
export const CLEAR_ALL_PRESENTATIONS = 'CLEAR_ALL_PRESENTATIONS';
export const UPDATE_PRESENTATION_TITLE_INNER = 'UPDATE_PRESENTATION_TITLE_INNER';
export const UPDATE_PRESENTATION_TITLE_OUTER = 'UPDATE_PRESENTATION_TITLE_OUTER';
export const UPDATE_IS_NEW_INNER = 'UPDATE_IS_NEW_INNER';
export const UPDATE_IS_NEW_OUTER = 'UPDATE_IS_NEW_OUTER';
export const UPDATE_LAST_UPDATED_INNER = "UPDATE_LAST_UPDATED_INNER";
export const UPDATE_LAST_UPDATED_OUTER = 'UPDATE_LAST_UPDATED_OUTER';
export const UPDATE_HAS_BEEN_UPLOADED_OUTER = 'UPDATE_HAS_BEEN_UPLOADED_OUTER';
export const UPDATE_HAS_BEEN_UPLOADED_INNER = 'UPDATE_HAS_BEEN_UPLOADED_INNER';
export const SET_SCORE_INCREMENT_INNER = 'SET_SCORE_INCREMENT_INNER';
export const SET_SCORE_INCREMENT_OUTER = 'SET_SCORE_INCREMENT_OUTER';
export const SET_TIMER_LENGTH_INNER = 'SET_TIMER_LENGTH_INNER';
export const SET_TIMER_LENGTH_OUTER = 'SET_TIMER_LENGTH_OUTER';
export const SET_GAME_TITLE = 'SET_GAME_TITLE';
export const SET_GAME_TITLE_INNER = 'SET_GAME_TTTLE_INNER';
export const SET_GAME_TITLE_OUTER = 'SET_GAME_TITLE_OUTER';
export const SET_SCORE_INCREMENT = 'SET_SCORE_INCREMENT';
export const SET_TIMER_LENGTH = 'SET_TIMER_LENGTH';
export const SET_IS_NEW = 'SET_IS_NEW';
export const SET_LAST_UPDATED = 'SET_LAST_UPDATED';
export const SET_HAS_BEEN_UPLOADED = 'SET_HAS_BEEN_UPLOADED';
export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';
export const SET_BACKGROUND_COLOR_INNER = 'SET_BACKGROUND_COLOR_INNER';
export const SET_BACKGROUND_COLOR_OUTER = 'SET_BACKGROUND_COLOR_OUTER';
export const SET_TEXT_COLOR = 'SET_TEXT_COLOR';
export const SET_TEXT_COLOR_INNER = 'SET_TEXT_COLOR_INNER';
export const SET_TEXT_COLOR_OUTER = 'SET_TEXT_COLOR_OUTER';
export const SET_ALERT_COLOR = 'SET_ALERT_COLOR';
export const SET_ALERT_COLOR_INNER = 'SET_ALERT_COLOR_INNER';
export const SET_ALERT_COLOR_OUTER = 'SET_ALERT_COLOR_OUTER';
  
// View Reducer
export const SET_MISS_PLAYING = 'SET_MISS_PLAYING';
export const SET_HIT_PLAYING = 'SET_HIT_PLAYING';
export const SET_GAME_OVER_PLAYING = 'SET_GAME_OVER_PLAYING';
export const RESET_VIEW = 'RESET_VIEW';