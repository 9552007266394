import {
    GET_USERS,
    CREATE_USER,
    PATCH_USER,
    DELETE_USER,
    SET_USER,
    SET_COMPANY_ID,
    SET_COMPANY_NAME,
    RESET_AUTH,
    SET_IS_AUTH,
    SET_AUTH_TOKEN,
    SET_HIDDEN_COMPANY_ID,
    SET_USER_VIEW,
    SET_COMPANY_USER_FILTER
  } from '../constants/actionTypes';
  import { CLOUD_API_URL } from '../constants/constants';
  // import firebase from 'firebase/app';
  //import * as firebase from '../Firebase/firebase'
  import {getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence ,onAuthStateChanged} from 'firebase/auth'
  
  //===================
  // GET
  //===================
  export const getUsersBegin = () => ({
    type: GET_USERS.BEGIN
  });
  
  export const getUsersSuccess = (array) => ({
    type: GET_USERS.SUCCESS,
    array
  });
  
  export const getUsersFailure = () => ({
    type: GET_USERS.FAILURE
  });
  
  //===================
  // POST
  //===================
  export const createUserBegin = () => ({
    type: CREATE_USER.BEGIN
  });
  
  export const createUserSuccess = (obj) => ({
    type: CREATE_USER.SUCCESS,
    obj
  });
  
  export const createUserFailure = () => ({
    type: CREATE_USER.FAILURE
  });
  
  //===================
  // PATCH
  //===================
  export const patchUserBegin = () => ({
    type: PATCH_USER.BEGIN
  });
  
  export const patchUserSuccess = (obj) => ({
    type: PATCH_USER.SUCCESS,
    obj
  });
  
  export const patchUserFailure = () => ({
    type: PATCH_USER.FAILURE
  });
  
  //===================
  // DELETE
  //===================
  export const deleteUserBegin = () => ({
    type: DELETE_USER.BEGIN
  });
  
  export const deleteUserSuccess = (id) => ({
    type: DELETE_USER.SUCCESS,
    id
  });
  
  export const deleteUserFailure = () => ({
    type: DELETE_USER.FAILURE
  });

  export function setUser(id) {  
    return dispatch => {  
        return dispatch({  
            type: SET_USER,
            payload: id
        });  
    }   
  }; 

  export function setCompanyId(id) {  
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_ID,
            payload: id
        });  
    }   
  }; 

  export function setIsAuth(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_IS_AUTH,
            payload: bool
        });  
    }   
  }; 

  export function setCompanyName(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_NAME,
            payload: string
        });  
    }   
  }; 

  export function setHiddenCompanyId(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_HIDDEN_COMPANY_ID,
            payload: string
        });  
    }   
  }; 

  export function setCompanyUserFilter(string) {  
    return dispatch => {  
        return dispatch({  
            type: SET_COMPANY_USER_FILTER,
            payload: string
        });  
    }   
  }; 

  export function setUserView(bool) {  
    return dispatch => {  
        return dispatch({  
            type: SET_USER_VIEW,
            payload: bool
        });  
    }   
  }; 

  export function resetAuth() {  
    return dispatch => {  
        return dispatch({  
            type: RESET_AUTH
        });  
    }   
  };
  
  export function setAuthToken(token) {  
    return dispatch => {  
        return dispatch({  
            type: SET_AUTH_TOKEN,
            payload: token
        });  
    }   
  }; 
  

  export const SignOut = () =>({type:"SIGNOUT"})
  // -----------------------
  // Users
  // -----------------------
  // GET all users
  export const getUsers = () => {
    return (dispatch) => {
      dispatch(getUsersBegin());
  
      // get idToken
      getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users`, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(getUsersFailure());
              }
              return response.json();
            })
            .then((data) => {
              // success
              //onsole.log("SUCCESS UG", data);
              dispatch(getUsersSuccess(data.users));
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(getUsersFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(getUsersFailure());
        });
    };
  };
  // -----------------------
  // Users
  // -----------------------
  // GET all users
  export const getUsersCompID = () => {
    return (dispatch) => {
      dispatch(getUsersBegin());
  
      // get idToken
      getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          // fetch /companies/:id/users /users/company
          fetch(`${CLOUD_API_URL}/users/company`, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(getUsersFailure());
              }
              return response.json();
            })
            .then((data) => {
              // success
              //onsole.log("SUCCESS UG", data);
              dispatch(getUsersSuccess(data.users));
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(getUsersFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(getUsersFailure());
        });
    };
  };
  
  // Create new user
  export const createUser = (data) => {
    return (dispatch) => {
      dispatch(createUserBegin());
  
      // modify data for valid createUser api structure
      let modifiedData = {
        ...data,
        displayName: data.name,
        companyName: data.companyName.label, // this is needed for redux store
        companyId: data.companyName.value,
        isActive: data.isActive === 'isActiveTrue' ? true : false,
        isAbleToAccessCms:
          data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false,
        // isAbleToCreateBriefcase:
        //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
        // isAbleToCreateGuestPass:
        //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue'
        //     ? true
        //     : false,
        team: [],
        portfolio: []
      };
      //onsole.log(modifiedData);
  
      // get idToken
      getAuth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          // fetch
          fetch(`${CLOUD_API_URL}/users`, {
            method: 'post',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(modifiedData)
          })
            .then((response) => {
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(createUserFailure());
              }
              return response.json();
            })
            .then((obj) => {
              // success
              //onsole.log(obj);
              dispatch(createUserSuccess({ ...modifiedData, id: obj.uid }));
            })
            .catch((error) => {
              // handle error
              console.error(error);
              dispatch(createUserFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(createUserFailure());
        });
    };
  };
  
  // Patch new user
  export const patchUser = (data) => {
    return (dispatch) => {
      dispatch(patchUserBegin());
  
      // NOTE companyName and companyId might return either obj or string so check and assign correctly, ie return "1234556" or {label: 'Company A', value: '456789'}
      // This is due to react-select + material-table combination
      let modifiedData = {
        ...data,
        displayName: data.name,
        companyName: data.companyName.label
          ? data.companyName.label
          : data.companyName, // this is needed for redux store
        companyId: data.companyName.value
          ? data.companyName.value
          : data.companyId,
        isActive: data.isActive === 'isActiveTrue' ? true : false,
        isAbleToAccessCms:
          data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false,
        // isAbleToCreateBriefcase:
        //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
        // isAbleToCreateGuestPass:
        //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue' ? true : false
      };
      //onsole.log(modifiedData);
  
      // check if modifiedData id exists
      if (!modifiedData.id || modifiedData.id === '') {
        console.error('No id given');
        dispatch(patchUserFailure());
      } else {
        // get idToken
        getAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // fetch
            fetch(`${CLOUD_API_URL}/users/${modifiedData.id}`, {
              method: 'patch',
              headers: {
                Authorization: `Bearer ${idToken}`,
                Accept: 'application/json, text/plain',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(modifiedData)
            })
              .then((response) => {
                if (!response.ok) {
                  // handle response error
                  console.error(response.statusText);
                  dispatch(patchUserFailure());
                }
  
                return response.json();
              })
              .then((obj) => {
                // success
                if (obj.success) {
                  dispatch(patchUserSuccess({ ...modifiedData }));
                } else {
                  console.error('Error in patch');
                  dispatch(patchUserFailure());
                }
              })
              .catch((error) => {
                // handle error
                console.error(error);
                dispatch(patchUserFailure());
              });
          })
          .catch(function (error) {
            // fail to get idToken
            console.error(error);
            dispatch(createUserFailure());
          });
      }
    };
  };
  
  // Patch user automatically through company
  export const patchUserAuto = (data) => {
    return (dispatch) => {
      dispatch(patchUserBegin());
  
      // NOTE companyName and companyId might return either obj or string so check and assign correctly, ie return "1234556" or {label: 'Company A', value: '456789'}
      // This is due to react-select + material-table combination
      let modifiedData = {
        ...data,
        displayName: data.name,
        companyName: data.companyName.label
          ? data.companyName.label
          : data.companyName, // this is needed for redux store
        companyId: data.companyName.value
          ? data.companyName.value
          : data.companyId,
        // isActive: data.isActive === 'isActiveTrue' ? true : false,
        // isAbleToAccessCms:
        //   data.isAbleToAccessCms === 'isAbleToAccessCmsTrue' ? true : false,
        // isAbleToCreateBriefcase:
        //   data.isAbleToCreateBriefcase === 'guestPassTrue' ? true : false,
        // isAbleToCreateGuestPass:
        //   data.isAbleToCreateGuestPass === 'portfolioGuestPassTrue' ? true : false
      };
      //onsole.log(modifiedData);
  
      // check if modifiedData id exists
      if (!modifiedData.id || modifiedData.id === '') {
        console.error('No id given');
        dispatch(patchUserFailure());
      } else {
        // get idToken
        getAuth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then(function (idToken) {
            // fetch
            fetch(`${CLOUD_API_URL}/users/${modifiedData.id}`, {
              method: 'patch',
              headers: {
                Authorization: `Bearer ${idToken}`,
                Accept: 'application/json, text/plain',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(modifiedData)
            })
              .then((response) => {
                if (!response.ok) {
                  // handle response error
                  console.error(response.statusText);
                  dispatch(patchUserFailure());
                }
  
                return response.json();
              })
              .then((obj) => {
                // success
                if (obj.success) {
                  dispatch(patchUserSuccess({ ...modifiedData }));
                } else {
                  console.error('Error in patch');
                  dispatch(patchUserFailure());
                }
              })
              .catch((error) => {
                // handle error
                console.error(error);
                dispatch(patchUserFailure());
              });
          })
          .catch(function (error) {
            // fail to get idToken
            console.error(error);
            dispatch(createUserFailure());
          });
      }
    };
  };
  
  // Delete user (destroy)
  export const deleteUser = (id) => {
    return (dispatch) => {
      dispatch(deleteUserBegin());
  
      // get idToken
      getAuth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          //onsole.log("DELETE FETCH START")
          // fetch
          fetch(`${CLOUD_API_URL}/users/${id}`, {
            method: 'DELETE',
            headers: {
              Authorization: `Bearer ${idToken}`,
              Accept: 'application/json, text/plain',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => {
              //onsole.log("DELETE FETCH END",response)
              if (!response.ok) {
                // handle response error
                console.error(response.statusText);
                dispatch(deleteUserFailure());
              }
              dispatch(deleteUserSuccess(id));
            })
            .catch((error) => {
              //onsole.log("DELETE FETCH ERROR",error)
              // handle error
              console.error(error);
              dispatch(deleteUserFailure());
            });
        })
        .catch(function (error) {
          // fail to get idToken
          console.error(error);
          dispatch(deleteUserFailure());
        });
    };
  };
  