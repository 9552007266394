import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import f2b from '../../assets/images/f2bbackground.jpg';
import {
    createPresentation,
    getFiles,
    getFilesByType
} from '../../utils/API';
// import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import logo from '../../assets/images/logo.png';
import { v4 as uuidv4 } from 'uuid';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import ReactTooltip from 'react-tooltip';
// import { confirmPasswordReset } from 'firebase/auth';

const StyledPresentationsContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .create-presentation-modal {
        z-index: 3;
        height: 100vh;
        width: 100vw;
        background-color: #3b3b3b80;
        opacity: ${props => props.modalOpen ? '1' : '0'};
        visibility: ${props => props.modalOpen ? 'visible' : 'hidden'};
        position: absolute;
        transition: 0.3s ease all;
    }

    .modal-content {
        z-index: 4;
        height: 280px;
        width: 400px;
        position: absolute;
        top: calc(50% - 140px);
        left: calc(50% - 200px);
        opacity: ${props => props.modalOpen ? '1' : '0'};
        visibility: ${props => props.modalOpen ? 'visible' : 'hidden'};
        transition: 0.3s ease all;
        background-color: white;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #3b3b3b31A;
        box-shadow: 5px 5px 5px 2px #3b3b3b80;
        
        .top {
            height: 30%;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: column;

            p {
                margin-block: 0px;
                font-size: 600;
                font-size: 18px;
                font-family: 'Merriweather Sans', sans-serif;
                user-select: none;
                letter-spacing: 0.5px;
                font-family: 'Space Mono', sans-serif;
            }

            input {
                width: 80%;
                height: 30px;
                text-align: center;
                border: none;
                border-bottom: 1px solid #3b3b3b;

                &:focus {
                    outline-bottom: 1px solid #3b3b3b;
                }
            }
        }

        .bottom {
            height: 30%;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .button {
                height: 35px;
                width: 130px;
                border-radius: 2px;
                box-shadow: 1px 1px 3px 0px #3b3b3b80;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                 p {
                    color: white;
                    margin-block: 0px;
                    font-family: 'Space Mono', sans-serif;
                    font-size: 700;
                    font-size: 17px;
                    cursor: inherit;
                    user-select: none;
                 }
            }
        }
    }

    .background-layer {
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        opacity: 0.2;
    }

    .logout {
        position: absolute;
        top: 0;
        right: 0;
        height: 80px;
        width: 80px;
        z-index: 1;
        border-bottom-left-radius: 8px;
        box-shadow: -1px 1px 3px 0px #3b3b3b80;
        background-color: rgb(7, 104, 176);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 36px;
            color: white;
        }
    }

    .create-presentation-overview {
        display: ${props => props.userView === true ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        height: 45px;
        width: 230px;
        position: absolute;
        top: 20px;
        left: calc(50% - 100px);

        .create-presentation {
            height: 100%;
            width: ${props => props.userView === true ? '100%' : '193px'};
            background-color: rgb(7,104,176);
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 2px solid #3b3b3b1A;
            // box-shadow: 2px 3px 5px 0px #3b3b3b80; 
            transition: 0.1s ease all;
            filter: ${props => props.buttonHovered ? 'brightness(120%)' : 'brightness(100%)'};
    
            p {
                margin-block: 0px;
                font-family: 'Merriweather Sans', sans-serif;
                font-size: 19px;
                color: white;
                cursor: inherit;
                user-select: none;
            }
        }

        .filter-user-company {
            height: 44.5px;
            width: 35px;
            margin-left: 2px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: red;
            background-color: ${props => props.companyUserFilter === 'company' ? '#0768b0' : 'rgb(38, 210, 184)'};

            svg {
                color: white;
                height: 20px;
                width: 20px;
                cursor: inherit;
            }
        }
    }

    .centering-box {
        height: calc(100% - 200px);
        width: 90%;
        display: flex;
        justify-content: ${props => props.allowedCentering ? 'flex-start' : 'center'};
        align-items: ${props => props.allowedCentering ? 'flex-start' : 'center'};
        align-content: ${props => props.allowedCentering ? 'flex-start' : 'center'};
        overflow-x: hidden;
        overflow-y: auto;
        flex-shrink: 0;
        flex-wrap: wrap;

        .centering-text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 100%;

            p {
                margin-block: 0px;
                font-family: 'Fredoka One', sans-serif;
                font-size: 24px;
                color: #3b3b3b;
                cursor: inherit;
                user-select: none;
            }
        }

        ::-webkit-scrollbar {
            width: 5px;
            transition: 0.3 ease all;
        }

        ::-webkit-scrollbar-track {
            background: transparent;
        }
        
        ::-webkit-scrollbar-thumb {
            background: #888888;
            border-radius: 200px;
            transition: 0.3 ease all;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: #555555;
        }
    }
`;

const StyledTile = styled.div`
    height: 250px;
    width: 18%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin: 1%;
    border-radius: 3px;
    box-shadow: 4px 5px 14px 0px #3b3b3b;
    position: relative;
    object-fit: contain;
    z-index: 2;
    flex-shrink: 0;

    @media (max-width: 1366px) {
        width: 21%;
        margin: 2%;
    }

    @media (max-width: 1024px) {
        width: 31.33%;
        margin: 1%;
    }

    @media (max-width: 767px) {
        width: 48%;
        margin: 1%;
    }

    @media (max-width: 450px) {
        width: 86%;
        margin: 7%;
        margin-top: 3%;
        margin-bottom: 3%;
    }

    &:hover {
        box-shadow: 4px 5px 14px 0px #0768b0;
    }

    .banner {
        width: 100%;
        height: 60px;
        background-color: #FFFFFFBF;
        overflow: hidden;
        padding: 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow; hidden;
            height: 50%;
            width: 100%;
        }

        p {
            margin-block: 0px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 24px;
            color: #3b3b3b;
            font-weight: 600;
            font-family: 'Space Mono', sans-serif;
        }

        span {
            margin-block: 0px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 18px;
            color: #3b3b3b;
            font-weight: 600;
            font-family: "Scoreboard", cursive;
        }
    }
`;

const Presentations = ({
    isAuth,
    setIsAuth,
    presentations,
    setPresentations,
    setPresentationId,
    setCurrentPresentation,
    userId,
    companyId,
    setFiles,
    setInstructions,
    setBackground,
    setLogo,
    setActive,
    setInactive,
    setHit,
    setMiss,
    setGameOver,
    setTitle,
    resetContent,
    resetAuth,
    resetView,
    availableBackgrounds,
    setAvailableBackgrounds,
    clearBackgrounds,
    clearAllPresentations,
    setScoreIncrement,
    setTimerLength,
    setIsNew,
    setHasBeenUploaded,
    setLastUpdated,
    authToken,
    setGameTitle,
    usedNames,
    setUsedNames,
    setCompanyName,
    setCompanyId,
    userView,
    companyName,
    companyUserFilter,
    setCompanyUserFilter,
    hiddenCompanyId,
    setBackgroundColor,
    setTextColor,
    setAlertColor
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [companyTitle, setCompanyTitle] = useState('');
    const [buttonHovered, setButtonHovered] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [nameError, setNameError] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (!isAuth) {
            history.push('/');
        }
    }, [isAuth, history]);

    // set list of available backgrounds for applying to the presentation tiles here
    useEffect(() => {
        clearBackgrounds();
        for (let presentation of presentations) {
            // console.log("PRESENTATION", presentation);
            setUsedNames(presentation.name.toLowerCase());
            getFilesByType(presentation.id, 'background').then((res) => {
                // console.log('background files', res.data);
                setAvailableBackgrounds(res.data.files[0]);
            });
        }
    }, [presentations, setAvailableBackgrounds, clearBackgrounds, setUsedNames]);

    const handleCompanyUserToggle = () => {
        if (companyUserFilter === 'company') {
            setCompanyUserFilter('user');
        } else if (companyUserFilter === 'user') {
            setCompanyUserFilter('company');
        }
    }

    const handleSetPresentation = (presentation, id) => {
        setCurrentPresentation(presentation);
        setPresentationId(id);
        setScoreIncrement(presentation.scoreIncrement);
        setTimerLength(presentation.timerLength);
        setHasBeenUploaded(presentation.hasBeenUploaded);
        setLastUpdated(presentation.lastUpdated);
        setIsNew(presentation.isNew);
        setGameTitle(presentation.gameTitle);
        setBackgroundColor(presentation.backgroundColor);
        setTextColor(presentation.textColor);
        setAlertColor(presentation.alertColor);
        if (userView === false) {
            setCompanyName(presentation.companyName);
            setCompanyId(presentation.companyId);
        }
        getFiles(id).then((res) => {
            console.log("FILES", res.data.files);
            // setFiles(res.data.files);
            let foundInstructions = res.data.files.find(foundFile => foundFile.fileScope === 'instructions');
            let foundBackground = res.data.files.find(foundFile => foundFile.fileScope === 'background');
            let foundLogo = res.data.files.find(foundFile => foundFile.fileScope === 'logo');
            let foundActive = res.data.files.find(foundFile => foundFile.fileScope === 'active');
            let foundInactive = res.data.files.find(foundFile => foundFile.fileScope === 'inactive');
            let foundHit = res.data.files.find(foundFile => foundFile.fileScope === 'hit');
            let foundMiss = res.data.files.find(foundFile => foundFile.fileScope === 'miss');
            let foundGameOver = res.data.files.find(foundFile => foundFile.fileScope === 'gameover');
            let foundTitle = presentation.name ? presentation.name : undefined;
            if (foundInstructions !== undefined) {
                setInstructions(foundInstructions)
            }
            if (foundBackground !== undefined) {
                setBackground(foundBackground);
            }
            if (foundLogo !== undefined) {
                setLogo(foundLogo);
            }
            if (foundActive !== undefined) {
                setActive(foundActive);
            }
            if (foundInactive !== undefined) {
                setInactive(foundInactive);
            }
            if (foundHit !== undefined) {
                setHit(foundHit);
            }
            if (foundMiss !== undefined) {
                setMiss(foundMiss);
            }
            if (foundGameOver !== undefined) {
                setGameOver(foundGameOver);
            }
            if (foundTitle !== undefined) {
                setTitle(foundTitle);
            }
        });
        history.push('/content');
    }

    const createNewPresentation = () => {
        if (!usedNames.includes(companyTitle.toString().toLowerCase())) {
            setDisabled(true)
            setTimeout(() => {
                setDisabled(false);
            }, 2000);
            toggleModalOpen();
            console.log("CREATING PRES");
            let presObj = {
                id: uuidv4(),
                userId: userId,
                companyId: companyId,
                companyName: companyName,
                name: companyTitle.trim(),
                token: authToken
            }
            createPresentation(presObj).then((res) => {
                console.log("PRESENTATION OBJECT", res);
                setPresentations(res.data.presentation);
            }).catch((err) => {
                console.log("ERROR", err);
            });
        } else {
            setNameError(true);
            setTimeout(() => {
                setNameError(false);
            }, 2500);
        }
    }

    const toggleModalOpen = () => {
        setModalOpen(!modalOpen);
    }

    let companyUserFilteredPresentations;
    let userViewFilteredPresentations;
    let allowedCentering = false;

    if (companyUserFilter === 'company') {
        companyUserFilteredPresentations = presentations;
    } else if (companyUserFilter === 'user') {
        companyUserFilteredPresentations = presentations.filter(pres => pres.userId === userId);
    } else {
        companyUserFilteredPresentations = presentations;
    }

    if (userView === true) {
        userViewFilteredPresentations = companyUserFilteredPresentations;
    } else if (userView === false) {
        userViewFilteredPresentations = companyUserFilteredPresentations.filter(pres => pres.companyId !== hiddenCompanyId);
    } else {
        userViewFilteredPresentations = companyUserFilteredPresentations;
    }

    if (userViewFilteredPresentations.length) {
        allowedCentering = true;
    }

    const presentationMapping = userViewFilteredPresentations.map((pres) => {
        let foundBackground = availableBackgrounds.find(file => file?.presentationId === pres?.id);
        return (
            <StyledTile 
                style={{
                    backgroundImage: `url('${foundBackground !== undefined ? foundBackground.url : ""}')`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
                key={pres.id}
                onClick={() => handleSetPresentation(pres, pres.id)}
            >
                <div className='banner'>
                    <div>
                        <p>{pres?.name ? pres.name : 'New Event'}</p>
                    </div>
                    <div>
                        <span>{pres?.gameTitle?.length ? pres.gameTitle.toUpperCase() : 'Agility Wall'}</span>
                    </div>
                </div>
            </StyledTile>
        )
    });

    const noPresentationsElement = (
        <div className='centering-text'>
            <p>
                {userView === true ? 
                    `There doesn't seem to be anything here. Please create a presentation to continue.` : 
                    `Sorry, it appears you haven't been assigned to any any external company presentations at this time.`
                }
            </p>
        </div>
    )

    return (
        <StyledPresentationsContainer modalOpen={modalOpen} buttonHovered={buttonHovered} presentations={presentations} userView={userView} companyUserFilter={companyUserFilter} allowedCentering={allowedCentering}>
            <div 
                className='background-layer'
                style={{
                    backgroundImage: `url(${f2b})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                }}
            />
            <div 
                className='create-presentation-modal' 
                onClick={() => {
                    toggleModalOpen();
                }}
            />
            <div className='modal-content'>
                <div className='top'>
                    <p style={{color: nameError ? 'red' : '#3b3b3b', fontSize: nameError ? '14px' : '22px'}}>
                        {nameError ? 'An event already exists by that name.' : 'Event Name'}
                    </p>
                    <input placeholder="Enter Name Here" value={companyTitle} onChange={(event) => setCompanyTitle(event.target.value)}/>
                </div>
                <div className='bottom'>
                    <div className='button' style={{backgroundColor: '#16d950'}} onClick={disabled || userView !== true ? null : () => createNewPresentation()}>
                        <p>Confirm</p>
                    </div>
                    <div className='button' style={{backgroundColor: '#ab3433'}} onClick={() => toggleModalOpen()}>
                        <p>Cancel</p>
                    </div>
                </div>
            </div>
            <div className='logout' onClick={() => {
                resetContent();
                resetAuth();
                resetView();
                history.push('/');
            }}>
                <ExitToAppRoundedIcon />
            </div>
            <div className='create-presentation-overview'>
                <div 
                    className='create-presentation' 
                    onClick={() => {
                        if (userView === true) {
                            toggleModalOpen();
                        }
                        setButtonHovered(false);
                    }}
                    onPointerEnter={() => setButtonHovered(true)}
                    onPointerLeave={() => setButtonHovered(false)}
                >
                    <p>Create Event</p>
                </div>
                <div 
                    className='filter-user-company'
                    data-tip={companyUserFilter === 'company' ? 'Showing all company presentations' : 'Showing user-specific presentations'}
                    onClick={() => userView === true ? handleCompanyUserToggle() : null}
                >
                    <ReactTooltip />
                    {companyUserFilter === 'company' 
                        ? 
                            <WorkRoundedIcon />
                        :
                            <PersonRoundedIcon />
                    }
                </div>
            </div>
            <div 
                className='centering-box'
            >
                {allowedCentering ? presentationMapping : noPresentationsElement}
            </div>
        </StyledPresentationsContainer>
    )

}

export default Presentations;
