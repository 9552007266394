import { connect } from 'react-redux';
import { SignOut } from './actions';
import { useEffect, useState } from 'react';
import C from './firebase/context';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './pages/Login';
import Presentations from './pages/Presentations';
import Content from './pages/Content';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <C.Consumer>
      {firebase => <PostConsumptionAppWithSignout Firebase={firebase}></PostConsumptionAppWithSignout>}
    </C.Consumer>
  );
}

function PostConsumptionApp ({ Firebase, SignOutD }) {
  const [Bit, SetBit] = useState(true);

  useEffect(() => {
    SignOutD();
  })

  return(
    <BrowserRouter>
      <Switch>
        <Route exact path='/'>
          <Login firebase={Firebase} Bit={Bit} SetBit={SetBit} />
        </Route>
        <Route path='/presentations'>
          <Presentations/>
        </Route>
        <Route path='/content'>
          <Content/>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
  return {
    SignOutD: () => dispatch(SignOut())
  };
};

const PostConsumptionAppWithSignout = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostConsumptionApp);


export default App;

function PrivateRoute({ children, firebase, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
      firebase.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}