import { connect } from 'react-redux';
import Content from './Content';
import {
  setIsAuth,
  setTitle,
  setInstructions,
  setBackground,
  setLogo,
  setActive,
  setInactive,
  setHit,
  setMiss,
  setGameOver,
  setHitPlaying,
  setMissPlaying,
  setGameOverPlaying,
  clearPresentation,
  resetContent,
  resetAuth,
  resetView,
  updatePresentationTitleOuter,
  updatePresentationTitleInner,
  setScoreIncrement,
  setTimerLength,
  setLastUpdated,
  setIsNew,
  setHasBeenUploaded,
  setTimerLengthInner,
  setTimerLengthOuter,
  setScoreIncrementInner,
  setScoreIncrementOuter,
  setLastUpdatedInner,
  setLastUpdatedOuter,
  setHasBeenUploadedInner,
  setHasBeenUploadedOuter,
  setIsNewInner,
  setIsNewOuter,
  setGameTitle,
  setGameTitleInner,
  setGameTitleOuter,
  setBackgroundColor,
  setBackgroundColorInner,
  setBackgroundColorOuter,
  setTextColor,
  setTextColorInner,
  setTextColorOuter,
  setAlertColor,
  setAlertColorInner,
  setAlertColorOuter
} from '../../actions';

const mapStateToProps = (state) => ({
  isAuth: state.authReducer.isAuth,
  title: state.contentReducer.title,
  instructions: state.contentReducer.instructions,
  background: state.contentReducer.background,
  logo: state.contentReducer.logo,
  active: state.contentReducer.active,
  inactive: state.contentReducer.inactive,
  hit: state.contentReducer.hit,
  miss: state.contentReducer.miss,
  gameOver: state.contentReducer.gameOver,
  isHitPlaying: state.viewReducer.isHitPlaying,
  isMissPlaying: state.viewReducer.isMissPlaying,
  isGameOverPlaying: state.viewReducer.isGameOverPlaying,
  presentationId: state.contentReducer.presentationId,
  timerLength: state.contentReducer.timerLength,
  scoreIncrement: state.contentReducer.scoreIncrement,
  companyName: state.authReducer.companyName,
  companyId: state.authReducer.companyId,
  isNew: state.contentReducer.isNew,
  lastUpdated: state.contentReducer.lastUpdated,
  hasBeenUploaded: state.contentReducer.hasBeenUploaded,
  gameTitle: state.contentReducer.gameTitle,
  backgroundColor: state.contentReducer.backgroundColor,
  textColor: state.contentReducer.textColor,
  alertColor: state.contentReducer.alertColor
});

const mapDispatchToProps = (dispatch) => {
  return {
    setIsAuth: (bool) => dispatch(setIsAuth(bool)),
    setTitle: (path) => dispatch(setTitle(path)),
    setInstructions: (path) => dispatch(setInstructions(path)),
    setBackground: (path) => dispatch(setBackground(path)),
    setLogo: (path) => dispatch(setLogo(path)),
    setActive: (path) => dispatch(setActive(path)),
    setInactive: (path) => dispatch(setInactive(path)),
    setHit: (path) => dispatch(setHit(path)),
    setMiss: (path) => dispatch(setMiss(path)),
    setGameOver: (path) => dispatch(setGameOver(path)),
    setHitPlaying: (bool) => dispatch(setHitPlaying(bool)),
    setMissPlaying: (bool) => dispatch(setMissPlaying(bool)),
    setGameOverPlaying: (bool) => dispatch(setGameOverPlaying(bool)),
    clearPresentation: () => dispatch(clearPresentation()),
    resetContent: () => dispatch(resetContent()),
    resetAuth: () => dispatch(resetAuth()),
    resetView: () => dispatch(resetView()),
    updatePresentationTitleOuter: (title) => dispatch(updatePresentationTitleOuter(title)),
    updatePresentationTitleInner: (title) => dispatch(updatePresentationTitleInner(title)),
    setScoreIncrement: (int) => dispatch(setScoreIncrement(int)),
    setTimerLength: (int) => dispatch(setTimerLength(int)),
    setIsNew: (bool) => dispatch(setIsNew(bool)),
    setLastUpdated: (date) => dispatch(setLastUpdated(date)),
    setHasBeenUploaded: (bool) => dispatch(setHasBeenUploaded(bool)),
    setLastUpdatedInner: (date) => dispatch(setLastUpdatedInner(date)),
    setLastUpdatedOuter: (obj) => dispatch(setLastUpdatedOuter(obj)),
    setIsNewInner: (bool) => dispatch(setIsNewInner(bool)),
    setIsNewOuter: (obj) => dispatch(setIsNewOuter(obj)),
    setHasBeenUploadedInner: (bool) => dispatch(setHasBeenUploadedInner(bool)),
    setHasBeenUploadedOuter: (obj) => dispatch(setHasBeenUploadedOuter(obj)),
    setScoreIncrementInner: (int) => dispatch(setScoreIncrementInner(int)),
    setScoreIncrementOuter: (obj) => dispatch(setScoreIncrementOuter(obj)),
    setTimerLengthInner: (int) => dispatch(setTimerLengthInner(int)),
    setTimerLengthOuter: (obj) => dispatch(setTimerLengthOuter(obj)),
    setGameTitle: (string) => dispatch(setGameTitle(string)),
    setGameTitleInner: (string) => dispatch(setGameTitleInner(string)),
    setGameTitleOuter: (obj) => dispatch(setGameTitleOuter(obj)),
    setBackgroundColor: (string) => dispatch(setBackgroundColor(string)),
    setBackgroundColorInner: (string) => dispatch(setBackgroundColorInner(string)),
    setBackgroundColorOuter: (obj) => dispatch(setBackgroundColorOuter(obj)),
    setTextColor: (string) => dispatch(setTextColor(string)),
    setTextColorInner: (string) => dispatch(setTextColorInner(string)),
    setTextColorOuter: (obj) => dispatch(setTextColorOuter(obj)),
    setAlertColor: (string)  => dispatch(setAlertColor(string)),
    setAlertColorInner: (string) => dispatch(setAlertColorInner(string)),
    setAlertColorOuter: (obj) => dispatch(setAlertColorOuter(obj))
  };
};

const ContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Content);

export default ContentContainer;
