import React, { useState } from 'react';
import styled from 'styled-components';
import {
    getPresentationsByFirestore,
    getPresentationsByCompany,
    verifyUser
} from '../../utils/API';
import { collection, query, where, getDocs } from "firebase/firestore";
import logo from '../../assets/images/logo.png';
import { useHistory } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
// import { CLOUD_API_URL } from '../../constants/constants';
import { Spinner } from 'react-bootstrap';

const StyledLoginContainer = styled.div`
    height: 100vh;
    width: 100%;
    transition: 0.4s all ease;
    background-color: #0768b0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledLoginBox = styled.div`
    height: 75%;
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    border-radius: 50px;
    box-shadow: 2px 2px 8px 3px #3b3b3b80;

    @media (orientation: portrait) {
        width: 70%;
    }

    @media (orientation: landscape) and (max-width: 1366px) {
        width: 50%;
    }

    @media (orientation: portrait) and (max-width: 450px) {
        height: 90%;
        width: 90%;
    }

    @media (orientation: landscape) and (max-width: 767px) {
        flex-direction: row;
        height: 80%;
        width: 90%;
    }
`;

const StyledLogoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
    height: min-content;

    @media (orientation: landscape) and (max-width: 767px) {
        width: 50%;
    }

    img {
        height: 250px;
        width: 450px;
        max-height: 100%;
        max-width: 100%;

        @media (orientation: portrait) and (max-width: 767px) {
            height: 150px;
            width: 270px;
            margin-bottom: 30px;
        }

        @media (orientation: landscape) and (max-width: 1024px) {
            height: 200px;
            width: 360px;
        }

        @media (orientation: landscape) and (max-width: 767px) {
            height: 150px;
            width: 270px;
        }

        @media (orientation: landscape) and (max-width: 650px) {
            height: 125px;
            width: 235px;
        }
    }
`;

const StyledLoginInputs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: min-content;
    width: 100%;

    .text-container {
        display: flex;
        justify-content: center;
        margin: 1%;
        width: 300px;

        p {
            text-align: center;
            letter-spacing: 0px;
            color: gray;
            line-height: 1em;
            font-size: 14px;
        }
    }

    .spinning-box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 1%;
        width: 300px;
    }
`;

const StyledForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input {
        margin: 10px;
        height: 42px;
        width: 300px;

        @media (orientation: landscape) and (max-width: 767px) {
            height: 35px !important;
            width: 225px;
        }
    }
`;

const StyledButtonContainer = styled.div`
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledButton = styled.button`
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    height: 42px;
    width: 140px;
    color: white;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0768b0;
    border-color: transparent;
    cursor: pointer;

    @media (orientation: landscape) and (max-width: 767px) {
        height: 35px !important;
        width: 225px;
    }
`;


const Login = ({
    firebase,
    setUser,
    setCompanyId,
    setCompanyName,
    setPresentations,
    setIsAuth,
    setAuthToken,
    setUserView,
    setHiddenCompanyId
}) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState("");
    const [errorMessage, setErrorMessage] = useState('');
    const [loggingIn, setLoggingIn] = useState(false);

    const history = useHistory();

    const testLogin = async () => {
        setLoggingIn(true);
        try {
            await firebase.doSignInWithEmailAndPassword(email, password);
            let T =  await getAuth().currentUser.getIdTokenResult();
            console.log("USER ID?", T.claims);
            setRole(T.claims.role);
            setUser(T.claims.user_id);
            getAuth().currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                setAuthToken(idToken);
                let userObj = {
                    userId: T.claims.user_id,
                    token: idToken
                }
                verifyUser(userObj).then((authRes) => {
                    console.log("AUTH RES", authRes);
                    if (!authRes.data.errors) {
                        window.sessionStorage.setItem('token', authRes.data.jwtToken);
                        setCompanyName(authRes.data.companyName);
                        setCompanyId(authRes.data.companyId);
                        getPresentationsByCompany(authRes.data.companyId).then((presRes) => {
                            console.log("PRES RES", presRes);
                            if (!presRes.data.errors) {
                                setPresentations(presRes.data.presentations);
                                setLoggingIn(false);
                                setIsAuth(true);
                                history.push('/presentations');
                            } else {
                                setLoggingIn(false);
                                setErrorMessage("Oops! We don't have an account with those credentials. Please try again.");
                                setTimeout(() => {
                                    setErrorMessage('');
                                }, 2500);
                            }
                        }).catch((err) => {
                            console.log("NO TOKEN")
                        })
                    } else {
                        setLoggingIn(false);
                        setErrorMessage("Oops! We don't have an account with those credentials. Please try again.");
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 2500);
                    }
                });
            });
        } catch(err) {
            console.log("ERROR", err);
            setLoggingIn(false);
            setErrorMessage("Oops! We don't have an account with those credentials. Please try again.");
            setTimeout(() => {
                setErrorMessage('');
            }, 2500);
        }
    }

    const testExternalLogin = async () => {
        setLoggingIn(true);
        try {
            await firebase.doSignInWithEmailAndPassword(email, password);
            let T =  await getAuth().currentUser.getIdTokenResult();
            console.log("USER ID?", T.claims);
            setRole(T.claims.role);
            setUser(T.claims.user_id);
            getAuth().currentUser.getIdToken(/* forceRefresh */ true)
            .then(function (idToken) {
                setAuthToken(idToken);
                let userObj = {
                    userId: T.claims.user_id,
                    token: idToken
                }
                verifyUser(userObj).then((authRes) => {
                    console.log("AUTH RES", authRes);
                    if (!authRes.data.errors) {
                        window.sessionStorage.setItem('token', authRes.data.jwtToken);
                        setHiddenCompanyId(authRes.data.companyId);
                        const q = query(collection(firebase.db, "events"), where("users", "array-contains", T.claims.user_id), where("application", "==", "AGW"));
                        getDocs(q).then(querySnapshot => {
                            let docList = querySnapshot.docs.map(doc => {
                                return {
                                    id: doc.id,
                                    data: doc.data()
                                }
                            });
                            // console.log("DOC LIST", docList);
                            if (docList.length) {
                                setUserView(false);
                                for (let doc of docList) {
                                    getPresentationsByFirestore(doc.data.company_id, doc.data.name).then(finalRes => {
                                        console.log("pres", finalRes);
                                        if (finalRes.data.presentations.length) {
                                            setPresentations(finalRes.data.presentations[0]);
                                        }
                                        setLoggingIn(false)
                                        setIsAuth(true);
                                        history.push('/presentations');
                                    });
                                }
                            } else {
                                setLoggingIn(false)
                                setErrorMessage("Oops! That account has no external presentations, or doesn't exist.");
                                setTimeout(() => {
                                    setErrorMessage("");
                                }, 2500);
                            }
                        }).catch(err => {
                            console.log("DOCUMENT RETRIEVAL FAILED");
                            setLoggingIn(false);
                            setErrorMessage("Oops! That account has no external presentations, or doesn't exist.");
                            setTimeout(() => {
                                setErrorMessage("");
                            }, 2500);
                        })
                    } else {
                        setLoggingIn(false);
                        setErrorMessage("Oops! We don't have an account with those credentials. Please try again.");
                        setTimeout(() => {
                            setErrorMessage('');
                        }, 2500);
                    }
                });
            });
        } catch(err) {
            console.log("ERROR", err);
            setLoggingIn(false);
            setErrorMessage("Oops! We don't have an account with those credentials. Please try again.");
            setTimeout(() => {
                setErrorMessage('');
            }, 2500);
        }
    }

    return(
        <StyledLoginContainer>
            <StyledLoginBox>
                <StyledLogoBox>
                    <img 
                        src={logo}
                        alt="logo"
                    />
                </StyledLogoBox>
                <StyledLoginInputs>
                    <StyledForm>
                        <input 
                            name="email"
                            placeholder="Email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            type="text"
                        />
                        <input
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            type="password"
                        />
                    </StyledForm>
                    <StyledButtonContainer>
                    {!loggingIn ? (
                        <>
                            <StyledButton
                                // onClick={() => this.loginAttempt({email: this.emailRef.current.value, password: this.passwordRef.current.value})}
                                onClick={() => {
                                    testLogin();
                                }}
                            >
                                Login
                            </StyledButton>
                            <StyledButton
                                // onClick={() => this.loginAttempt({email: this.emailRef.current.value, password: this.passwordRef.current.value})}
                                onClick={() => {
                                    testExternalLogin();
                                }}
                            >
                                CM External Login
                            </StyledButton>
                        </>
                    ) : (
                        <div className='spinning-box'>
                            <Spinner animation="border" role="status" variant="dark">
                                {/* <span className="sr-only">Loading...</span> */}
                            </Spinner>
                        </div>
                    )}
                    </StyledButtonContainer>
                    <div className="text-container">
                        {errorMessage.length ? (
                                <p style={{color: '#d61320'}}>{errorMessage}</p>
                            ) : (
                                <p>For help with passwords, please call:<br></br> 1-908-428-8030 ext. 1003</p>
                            )
                        }
                    </div>
                </StyledLoginInputs>
            </StyledLoginBox>
        </StyledLoginContainer>
    );
}


export default Login;


